import {} from "@heroicons/react/24/solid";
import React, { useEffect, useState } from "react";
import OrderHistoryFeed from "../OrderHistoryFeed/OrderHistoryFeed";
import { AnimatePresence, motion } from "framer-motion";
import { useReportContext } from "../../utils/ReportContext";
import { useSettingsContext } from "../../utils/SettingsContext";
import { Fragment } from 'react'
import { ChatBubbleLeftEllipsisIcon, TagIcon, UserCircleIcon } from '@heroicons/react/20/solid'


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function ReportSummary() {
  const { displayOrderHistory } = useReportContext();
  const { shareView } = useReportContext();
  const { clientName } = useSettingsContext();

  return (
    <>
      <div className="min-h-full">
        <div className="py-4">
          <div className="mx-auto mt-4 grid max-w-3xl grid-cols-1 gap-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
            {/* Left Column */}
            <div className="lg:col-span-2 lg:col-start-1">
              <ContentStrategySection />
              <hr className="lg:hidden" />
            </div>

            {/* Right Column */}
            <section aria-labelledby="timeline-title" className="lg:col-span-1 lg:col-start-3">
              <AnimatePresence initial={false}>
                {displayOrderHistory && !shareView && (
                  <motion.div className="bg-gray-50 px-4 py-5 shadow-0 sm:rounded-lg sm:px-6 top-4" initial={{ opacity: 0, scale: 0.9 }} animate={{ opacity: 1, scale: 1, transition: { type: "tween", duration: 0.2 } }} exit={{ opacity: 0, scale: 0.9, transformOrigin: "center center", transition: { duration: 0.1 } }}>
                    <h2 id="timeline-title" className="text-lg font-bold text-gray-800">
                      Order History
                    </h2>
                    <p className="text-gray-500 font-sm mt-2">A list of the most recent orders {clientName ? "for " + clientName : null}</p>
                    <OrderHistoryFeed historyFeed={[1, 2, 3]} />
                  </motion.div>
                )}
              </AnimatePresence>
              <hr className="mt-5" />
              <OrderInputsSection />
            </section>
          </div>
        </div>
      </div>
    </>
  );
}


function ContentStrategySection() {
  const activity = [
    {
      id: 1,
      title: `Topic Ideation & Validation`,
      description:
        'First, we research and validate topics in your niche that your prospects are eager to consume. By doing this first, we make sure that each piece is going to be a hit!',
      status: 'Complete (1 of 1)',
    },
    {
      id: 2,
      title: `Content Creation`,
      description:
        <>
          <p className="mb-2">After you have approved your topics, our expert team of copy writers goes to work crafting your high-quality content.</p>
          <p className="mb-2">Each article:</p>
          <ul className="list-disc list-inside">
            <li>is optimized to rank well in search engines</li>
            <li>is formatted for the web and easy readability</li>
            <li>includes internal links to improve your site's SEO</li>
            <li>includes a call to action to spur readers into action</li>
          </ul>
        </>,
      status: 'Complete (1 of 1)',
    },
    {
      id: 3,
      title: `Content Delivery`,
      description:
        'Your content is available in HTML format below for you to publish on your website',
      status: 'Complete (1 of 1)',
    },
  ]

  function Number({ children }) {
    return (
      <div className="relative">
        <div className="h-8 w-8 pt-[0.055rem] pl-[0.05rem] items-center leading-[0rem] text-sm text-center grid place-items-center font-medium text-blue-500 justify-center border-2 border-blue-500 rounded-full bg-white ring-4 ring-white">{children}</div>
      </div>
    )
  }

  function Pill({ children }) {
    return (
      <p className="bg-[#2eaa71] rounded-full px-3 py-0.5 pt-0.5 tracking-[0.5px] text-[0.65rem] text-white">{children}</p>
    )
  }

  return (
    <section id="contentStrategy" class="flow-root max-w-xl">

      <div class="header__section">
        <h2 className="text-xl lg:text-2xl font-bold text-gray-700 mb-4">Content Strategy</h2>
        <p className="text-gray-700 leading-relaxed max-w-xl text-sm mb-8 mt-2">By publishing high-quality content on a consistent basis, your target customer will see you as an authority. This is true inbound marketing: High-quality content created to educate, inform, and draw in your prospects.</p>
      </div>

      <ul className="max-w-xl">
        {activity.map((item, index) => (
          <li key={item.id}>
            <div className="relative pb-8">
              {index !== activity.length - 1 ? (
                <span className="absolute left-4 -ml-0.5 top-5 -ml-px h-full w-0.5 border-l border-dashed border-gray-300" aria-hidden="true" />
              ) : null}
              <div className="relative flex items-start space-x-3">
                <Number>{index + 1}</Number>
                <div className="min-w-0 flex-1 pl-5">
                  <div className="flex items-center justify-start gap-x-4">
                    <h3 className="text-lg font-medium text-gray-700">
                      {item.title}
                    </h3>
                    <Pill>{item.status}</Pill>
                  </div>
                  <div className="mt-1 text-sm text-gray-500 leading-relaxed">
                    <div>{item.description}</div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </section>
  )
}

function OrderInputsSection() {
  const [customerInputs, setCustomerInputs] = useState(null);
  const { reportData } = useReportContext();

  const questions = [
    {
      title: "What is your current blog URL",
      attributeName: "currentBlogUrl",
    },
    {
      title: "What is your product or service?",
      attributeName: "productOrService",
    },
    {
      title: "Target Customer",
      attributeName: "targetCustomer",
    },
    {
      title: "Topic ideas",
      attributeName: "topicSuggestions",
    },
    {
      title: "Special writing instructions",
      attributeName: "specialWritingInstructions",
    },
    {
      title: "Who is your competition?",
      attributeName: "competition",
    },
    {
      title: "Voice",
      attributeName: "voice",
    },
    {
      title: "Posting Instructions",
      attributeName: "postingInstructions",
    },
    {
      title: "Posting content to WordPress?",
      attributeName: "postContentToWP",
    },
    {
      title: "English Preference",
      attributeName: "englishPreference",
    },
    {
      title: "Goal of Content",
      attributeName: "goalOfContent",
    },
    {
      title: "Audience Level",
      attributeName: "audienceLevel",
    },
    {
      title: "Link examples",
      attributeName: "linkExamples",
    },
    {
      title: "Things to mention in the article ✅",
      attributeName: "thingsToMention",
    },
    {
      title: "Things NOT to mention in the article ⛔️",
      attributeName: "thingsNotToMention",
    },
    {
      title: "How to exceed expectations?",
      attributeName: "howToExceedExpectations",
    },
    {
      title: "Tone",
      attributeName: "tone",
    },
    {
      title: "Sourcing",
      attributeName: "sourcing",
    },
    {
      title: "Additional Info",
      attributeName: "additionalInfo",
    },
  ];

  useEffect(() => {
    if (reportData.orderid) {
      setCustomerInputs(reportData.customerInputs);
    }
  }, [reportData]);

  return (
    <section className="bg-gray-50 px-4 py-5 shadow-0 sm:rounded-lg sm:px-6 mt-4 space-y-6">
      <h2 className="text-lg font-bold text-gray-800">Order Inputs</h2>

      {customerInputs && (
        <>
          {questions.map((question) => {
            const { title, attributeName } = question;
            const response = customerInputs[attributeName];

            return response ? (
              <section key={attributeName}>
                <h3 className="font-semibold mb-1 text-sm">{title}</h3>
                {attributeName === "currentBlogUrl" ? (
                  <a href={response} className="text-blue-400  text-sm hover:text-blue-500 duration-100 underline break-all" target="_blank" rel="noopener noreferrer">
                    {response}
                  </a>
                ) : (
                  <p className="text-gray-500 text-sm break-all">{response}</p>
                )}
              </section>
            ) : null;
          })}
        </>
      )}
    </section>
  )
}
