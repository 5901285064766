import React, { useState, useEffect } from "react";
import { useSettingsContext } from "../utils/SettingsContext";
import { useReportContext } from "../utils/ReportContext";
import HeaderCard from "../components/HeaderCard/HeaderCard";
import ReportTabs from "../components/ReportTabs/ReportTabs";
import ReportSummary from "../components/ReportSummary/ReportSummary";
import Articles from "../components/Articles/Articles";
import { AnimatePresence, motion } from "framer-motion";

export default function BloggerReport({ previewMode = false, customizationSettings }) {
  const { pullUserSettings, settingsOpen, setSettingsOpen, ...restSettings } = useSettingsContext();
  const { reportData, displayOrderSummary } = useReportContext();
  const [currentTab, setCurrentTab] = useState("Articles");
  const [entries, setEntries] = useState([]);
  //const settings = customizationSettings || restSettings; // Create settings object

  const tabs = [
    { name: "Articles", href: "#", count: "4", current: true },
    { name: "Summary", href: "#", current: false },
  ];

  useEffect(() => {
    if (reportData.userid) {
      pullUserSettings(reportData.userid);
    }
  }, [reportData, pullUserSettings]);

  // If you hide the order summary tab, set the current tab to Articles
  useEffect(() => {
    if (!displayOrderSummary) {
      setCurrentTab("Articles");
    }
  }, [displayOrderSummary]);

  useEffect(() => {
    let entries;
    //console.log("reportData: ", reportData);
    if (reportData.orderid) {
      entries = reportData.entries;
    }

    /*
    let entry1 = { id: "001", article: { title: "March Is Workplace Eye Wellness Month", seo_description: "SEO description" }, topic: { keywords: "my keywords " } };
    let entry2 = { id: "002", article: { title: "March Is Workplace Eye Wellness Month", seo_description: "SEO description" }, topic: { keywords: "my keywords " } };
    //entries = [entry1, entry2];*/

    setEntries(entries || []);
  }, [reportData]);

  return (
    <>
      <motion.div>
        <HeaderCard settingsOpen={settingsOpen} setSettingsOpen={setSettingsOpen} />
        <AnimatePresence initial={false}>
          {!settingsOpen || displayOrderSummary ? (
            <div className="overflow-hidden">
              <motion.div initial={{ opacity: 0, y: "100%", height: 0 }} animate={{ opacity: 1, y: 0, height: "auto", transition: { type: "tween", ease: "easeIn", duration: 0.3 } }} exit={{ opacity: 0, y: "100%", height: 0, transition: { type: "tween", ease: "easeOut", duration: 0.3 } }}>
                <ReportTabs tabs={tabs} currentTab={currentTab} onToggle={(value) => setCurrentTab(value)}></ReportTabs>
              </motion.div>
            </div>
          ) : null}
        </AnimatePresence>
        {currentTab === "Articles" && <Articles entries={entries} />}
        {currentTab === "Summary" && <ReportSummary />}
      </motion.div>
    </>
  );
}
