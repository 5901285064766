import React, { useState, useEffect } from "react";
import { useSettingsContext } from "../utils/SettingsContext";
import { useReportContext } from "../utils/ReportContext";
import ReportTabs from "../components/ReportTabs/ReportTabs";
import Pages from "../components/WebCopy/Pages";
import WebCopySummary from "../components/WebCopy/WebCopySummary";
import HeaderCard from "../components/HeaderCard/HeaderCard";
import { AnimatePresence, motion } from "framer-motion";

export default function WebCopyReport(props) {
  const { pullUserSettings, settingsOpen, setSettingsOpen, ...restSettings } = useSettingsContext();
  const [articleDetailsOpen, setArticleDetailsOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState("Pages");
  const [items, setItems] = useState([]);
  const { reportData, displayOrderSummary } = useReportContext();

  useEffect(() => {
    if (reportData.userid) {
      pullUserSettings(reportData.userid);
    }
  }, [reportData, pullUserSettings]);

  // If you hide the order summary tab, set the current tab to Articles
  useEffect(() => {
    if (!displayOrderSummary) {
      setCurrentTab("Pages");
    }
  }, [displayOrderSummary]);

  const tabs = [
    { name: "Pages", href: "#", current: true },
    { name: "Summary", href: "#", current: false },
  ];

  useEffect(() => {
    let items;
    //console.log("reportData: ", reportData);
    if (reportData.orderid) {
      items = reportData.items;
    }

    /* let entry1 = { id: "001", article: { title: "Lean Six Sigma Certification From Lean Six Sigma Tool", seo_description: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede." }, topic: { url: "https://leansixsigmatoolbox.com/" } };
    let entry2 = { id: "002", article: { title: "Lean Six Sigma Certification From Lean Six Sigma Tool", seo_description: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede." }, topic: { url: "https://leansixsigmatoolbox.com/" } };
    let entry3 = { id: "003", article: { title: "Lean Six Sigma Certification From Lean Six Sigma Tool", seo_description: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede." }, topic: { url: "https://leansixsigmatoolbox.com/" } };

    entries = [entry1, entry2];*/

    setItems(items || []);
  }, [reportData]);

  return (
    <>
      <div>
        <HeaderCard settingsOpen={settingsOpen} setSettingsOpen={setSettingsOpen} />
        <AnimatePresence initial={false}>
          {!settingsOpen || displayOrderSummary ? (
            <div key="reportTabs" className="overflow-hidden">
              <motion.div key="tabs" initial={{ opacity: 0, y: "100%", height: 0 }} animate={{ opacity: 1, y: 0, height: "auto", transition: { type: "tween", ease: "easeIn", duration: 0.3 } }} exit={{ opacity: 0, y: "100%", height: 0, transition: { type: "tween", ease: "easeOut", duration: 0.3 } }}>
                <ReportTabs tabs={tabs} currentTab={currentTab} onToggle={(value) => setCurrentTab(value)}></ReportTabs>
              </motion.div>
            </div>
          ) : null}

          {currentTab === "Pages" && <Pages key="pagesKey" items={items} />}
          {currentTab === "Summary" && <WebCopySummary key="summaryKey" />}
        </AnimatePresence>
      </div>
    </>
  );
}
