import { PhoneIcon } from "@heroicons/react/20/solid";
import React, { useEffect, useState, useRef } from "react";
import { classNames } from "../../utils/utils";

export default function TextInput(props) {
  const { icon, title, state, setState, showError, wiggle } = props;

  const inputRef = useRef(null);

  useEffect(() => {
    if (wiggle && inputRef.current) {
      inputRef.current.focus();
      console.log("Text input error focus");
    }
  }, [wiggle]);

  function handleChange(e) {
    setState(e.target.value);
  }

  return (
    <div>
      {title ? (
        <label htmlFor={title} className="block mb-1 text-sm font-semibold leading-6 text-gray-900">
          {title}
        </label>
      ) : null}
      <div className="relative mt-0 mb-3">
        {icon && (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <props.icon className="h-4 w-4 text-gray-400" aria-hidden="true" />
          </div>
        )}
        <div className={classNames(showError && wiggle ? "animate-wiggle" : "")}>
          <input ref={inputRef} type={title} name={title} id={title} className={classNames(showError ? "ring-red-500 focus:ring-red-600 outline-red-600" : "ring-gray-300 focus:ring-blue-600", props.icon && "pl-8", " block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-1 focus:ring-inset  sm:text-sm sm:leading-6")} value={state} onChange={handleChange} />
        </div>
        {showError && <p className="mt-1 font-semibold text-xs text-red-500">Required field</p>}
        {title ? <div className="w-full border-t border-gray-300 my-4" /> : null}
      </div>
    </div>
  );
}
