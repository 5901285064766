import React, { useEffect, useState } from "react";
import { useSettingsContext } from "../../utils/SettingsContext";
import { useReportContext } from "../../utils/ReportContext";

import TextInput from "./TextInput";
import ImageUploadField from "./ImageUploadField";
import SwitchToggle from "./SwitchToggle";
import { Disclosure } from "@headlessui/react";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";
import ColorPicker from "./ColorPicker";
import WebsiteInput from "./WebsiteInput";
import { PhoneIcon } from "@heroicons/react/20/solid";

export default function CustomizeForm({ formSubmitted, wiggleInput, onFormValidChange }) {
  const { bgColor, setBgColor, agencyName, setAgencyName, displayWebsite, setDisplayWebsite, agencyWebsite, setAgencyWebsite, displayAddress, setDisplayAddress, agencyAddress, setAgencyAddress, displayPhone, setDisplayPhone, agencyPhone, setAgencyPhone, clientName, setClientName, imageServeUrl, imageId, fileUpload, handleUserSave } = useSettingsContext();
  const { reportData, displayOrderSummary, setDisplayOrderSummary, displayOrderHistory, setDisplayOrderHistory, displayNotes, setDisplayNotes, displayWriterInfo, setDisplayWriterInfo, handleOrderSave } = useReportContext();

  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    if (reportData.orderid) {
      handleOrderSave(reportData.orderid);
      handleUserSave(reportData.userid);
    }
  }, [bgColor, agencyName, displayWebsite, agencyWebsite, displayAddress, agencyAddress, displayPhone, agencyPhone, clientName, imageServeUrl, imageId, fileUpload, displayOrderSummary, displayOrderHistory, displayNotes, displayWriterInfo, handleOrderSave, handleUserSave]);

  // Parent component can check if form is valid with onFormValidChange callback
  useEffect(() => {
    setIsFormValid(!!agencyName);
    if (onFormValidChange) {
      onFormValidChange(!!agencyName);
    }
  }, [agencyName, onFormValidChange]);

  return (
    <div className="p-8 pt-0 w-full">
      <ColorPicker title="Primary Brand Color" state={bgColor} setState={setBgColor}></ColorPicker>
      <TextInput title="Agency Name" state={agencyName} setState={setAgencyName} showError={formSubmitted && !agencyName} wiggle={wiggleInput} />
      <ImageUploadField />
      <SwitchToggle title="Agency Website" state={displayWebsite} setState={setDisplayWebsite} />
      {displayWebsite ? <WebsiteInput title={null} state={agencyWebsite} setState={setAgencyWebsite} /> : null}
      <SwitchToggle title="Agency Address" state={displayAddress} setState={setDisplayAddress} />
      {displayAddress ? <TextInput title={null} state={agencyAddress} setState={setAgencyAddress} /> : null}
      <SwitchToggle title="Agency Phone" state={displayPhone} setState={setDisplayPhone} />
      {displayPhone ? <TextInput icon={PhoneIcon} title={null} state={agencyPhone} setState={setAgencyPhone} /> : null}
      <div className="w-full border-t border-gray-300 my-4" />
      <TextInput title="Client Name" state={clientName} setState={setClientName} />
      <div>
        <div>
          <div>
            <dl>
              <Disclosure as="div">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="block text-sm font-semibold leading-6 text-gray-900">Show/Hide Sections</span>
                        <span className="ml-6 flex h-7 items-center">{open ? <MinusSmallIcon className="h-6 w-6" aria-hidden="true" /> : <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />}</span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2">
                      <SwitchToggle title="Order Summary" state={displayOrderSummary} setState={setDisplayOrderSummary} />
                      <SwitchToggle title="Order History" state={displayOrderHistory} setState={setDisplayOrderHistory} />
                      <SwitchToggle title="Notes" state={displayNotes} setState={setDisplayNotes} />
                      <SwitchToggle title="Writer Info" state={displayWriterInfo} setState={setDisplayWriterInfo} />
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}
