import { useState } from "react";
import { useSettingsContext } from "../../utils/SettingsContext";
import { classNames } from "../../utils/utils";

export default function ReportTabs(props) {
  const [toggleState, setToggleState] = useState(props.currentTab);
  const { getPrimaryColor } = useSettingsContext();

  const activeStyle = {
    color: getPrimaryColor(),
    borderColor: getPrimaryColor(),
  };

  function toggleHandler(tabName) {
    setToggleState(tabName);
    props.onToggle(tabName);
  }

  function changeHandler(event) {
    console.log(event);
    toggleHandler(event.target.value);
  }

  return (
    <div className="mx-auto pt-4 grid gap-6 max-w-7xl">
      <div className="hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select onChange={changeHandler} id="tabs" name="tabs" className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm" defaultValue={props.tabs.find((tab) => tab.current).name}>
          {props.tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {props.tabs.map((tab, index) => (
              <span style={tab.name === props.currentTab ? activeStyle : null} key={index} onClick={() => toggleHandler(tab.name)} className={classNames(tab.name === props.currentTab ? "border-red-500 text-red-600" : "border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700", "flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer")} aria-current={tab.current ? "page" : undefined}>
                {tab.name}
                {tab.count && false ? <span className={classNames(tab.name === props.currentTab ? "bg-red-100 text-red-600" : "bg-gray-100 text-gray-900", "ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block")}>{tab.count}</span> : null}
              </span>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}
