import React, { createContext, useContext, useState, useCallback } from "react";
import { BRANDED_BACKGROUND_COLOR, BRANDED_PRIMARY_COLOR, BRANDED_SECONDARY_COLOR, WHITELABEL_DEFAULT_BACKGROUND_COLOR, WHITELABEL_DEFAULT_LINKS_COLOR } from "../constants/colors";
import { getContrastingColor } from "./colorFunctions";
import { useReportContext } from "./ReportContext";

const SettingsContext = createContext();
export const useSettingsContext = () => useContext(SettingsContext);

const defaultBgColor = "#232323";

export default function SettingsContextProvider(props) {
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [bgColor, setBgColor] = useState(defaultBgColor);
  const [agencyName, setAgencyName] = useState("");
  const [uploadUrl, setUploadUrl] = useState("");
  const [imageId, setImageId] = useState("");
  const [imageServeUrl, setImageServeUrl] = useState(null);
  const [displayWebsite, setDisplayWebsite] = useState(false);
  const [agencyWebsite, setAgencyWebsite] = useState("");
  const [displayAddress, setDisplayAddress] = useState(false);
  const [agencyAddress, setAgencyAddress] = useState("");
  const [displayPhone, setDisplayPhone] = useState(false);
  const [agencyPhone, setAgencyPhone] = useState("");
  const [clientName, setClientName] = useState("");
  const { shareView } = useReportContext();

  const [fileUpload, setFileUpload] = useState(null);

  // Color logic
  // check if the settings are open and the user has selected a color before returning the bgColor
  const getBgColor = () => {
    if ((settingsOpen && bgColor !== WHITELABEL_DEFAULT_BACKGROUND_COLOR) || shareView) {
      return bgColor; // user selected a color
    }
    return BRANDED_BACKGROUND_COLOR; // default background color when settings are closed or the user hasn't selected a color yet
  };

  const getPrimaryColor = () => {
    if ((settingsOpen && bgColor !== WHITELABEL_DEFAULT_LINKS_COLOR) || shareView) {
      return bgColor; // user selected a color
    }
    return BRANDED_PRIMARY_COLOR; // default background color when settings are closed or the user hasn't selected a color yet
  };

  const getSecondaryColor = () => {
    if ((settingsOpen && bgColor !== WHITELABEL_DEFAULT_BACKGROUND_COLOR) || shareView) {
      return getContrastingColor(bgColor); // user selected a color
    }
    return BRANDED_SECONDARY_COLOR; // default background color when settings are closed or the user hasn't selected a color yet
  };

  const pullUserSettings = useCallback(async (userId) => {
    let response = await fetch(`https://www.thehoth.com/internalapi/campaignreport/user/${userId}`, {
      method: "GET",
    });
    if (!response.ok) {
      let details = await response.text();
      throw new Error(details);
    }
    const userSettings = await response.json();
    // console.log('saved settings = ', settings)

    if (userSettings.meta.bgColor) {
      setBgColor(userSettings.meta.bgColor);
    }
    if (userSettings.meta.agencyName) {
      setAgencyName(userSettings.meta.agencyName);
    }
    if (userSettings.meta.imageId) {
      setImageId(userSettings.meta.imageId);
    }
    if (userSettings.meta.imageServeUrl) {
      setImageServeUrl(userSettings.meta.imageServeUrl);
    }
    if (userSettings.meta.fileUpload) {
      setFileUpload(userSettings.meta.fileUpload);
    }
    if (userSettings.meta.displayWebsite) {
      setDisplayWebsite(userSettings.meta.displayWebsite);
    }
    if (userSettings.meta.agencyWebsite) {
      setAgencyWebsite(userSettings.meta.agencyWebsite);
    }
    if (userSettings.meta.displayAddress) {
      setDisplayAddress(userSettings.meta.displayAddress);
    }
    if (userSettings.meta.agencyAddress) {
      setAgencyAddress(userSettings.meta.agencyAddress);
    }
    if (userSettings.meta.displayPhone) {
      setDisplayPhone(userSettings.meta.displayPhone);
    }
    if (userSettings.meta.agencyPhone) {
      setAgencyPhone(userSettings.meta.agencyPhone);
    }
    if (userSettings.meta.clientName) {
      setClientName(userSettings.meta.clientName);
    }
  }, []);

  const handleUserSave = useCallback(
    async (userId) => {
      let userSettings = {};
      if (bgColor !== defaultBgColor) {
        // TODO: default color constant?
        userSettings.bgColor = bgColor;
      }
      if (agencyName !== "") {
        userSettings.agencyName = agencyName;
      }
      if (displayWebsite !== false) {
        userSettings.displayWebsite = displayWebsite;
      }
      if (agencyWebsite !== "" && displayWebsite) {
        userSettings.agencyWebsite = agencyWebsite;
      }
      if (displayAddress !== false) {
        userSettings.displayAddress = displayAddress;
      }
      if (agencyAddress !== "" && displayAddress) {
        userSettings.agencyAddress = agencyAddress;
      }
      if (displayPhone !== false) {
        userSettings.displayPhone = displayPhone;
      }
      if (agencyPhone !== "" && displayPhone) {
        userSettings.agencyPhone = agencyPhone;
      }
      if (clientName !== "") {
        userSettings.clientName = clientName;
      }
      if (imageServeUrl) {
        userSettings.imageServeUrl = imageServeUrl;
        userSettings.imageId = imageId;
        userSettings.fileUpload = fileUpload;
      }
      // if there is an existing saved image url in the pulled settings...
      if (imageServeUrl && !document.getElementById("file-upload").files.length > 0) {
        userSettings.imageServeUrl = imageServeUrl;
        userSettings.imageId = imageId;
      }
      //console.log("user settings = ", userSettings);
      await fetch(`https://www.thehoth.com/internalapi/campaignreport/user/${userId}`, {
        method: "POST",
        body: JSON.stringify(userSettings),
        credentials: "include",
        headers: {
          "content-type": "application/json",
        },
      });
    },
    [bgColor, agencyName, displayWebsite, agencyWebsite, displayAddress, agencyAddress, displayPhone, agencyPhone, clientName, imageServeUrl, imageId, fileUpload]
  );

  return (
    <SettingsContext.Provider
      value={{
        settingsOpen,
        setSettingsOpen,
        bgColor,
        setBgColor,
        getBgColor,
        getPrimaryColor,
        getSecondaryColor,
        agencyName,
        setAgencyName,
        uploadUrl,
        setUploadUrl,
        imageId,
        setImageId,
        imageServeUrl,
        setImageServeUrl,
        displayWebsite,
        setDisplayWebsite,
        agencyWebsite,
        setAgencyWebsite,
        displayAddress,
        setDisplayAddress,
        agencyAddress,
        setAgencyAddress,
        displayPhone,
        setDisplayPhone,
        agencyPhone,
        setAgencyPhone,
        clientName,
        setClientName,
        fileUpload,
        setFileUpload,
        pullUserSettings,
        handleUserSave,
      }}
    >
      {props.children}
    </SettingsContext.Provider>
  );
}
