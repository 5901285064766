import React from "react";
import ReactDOM from "react-dom";
import { useSettingsContext } from "../../utils/SettingsContext";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";

export default function CopyHTMLAsFormattedIconButton({ label, html }, props) {
  const [copied, setCopied] = React.useState(false);
  const { getSecondaryColor } = useSettingsContext();

  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    console.log("close");
    setOpen(false);
  };

  const handleClick = () => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html;

    const headings = tempElement.querySelectorAll("h1, h2, h3, h4, h5, h6");

    // Apply heading styles
    headings.forEach((heading) => {
      const lineBreakBefore = document.createElement("br");
      heading.parentNode.insertBefore(lineBreakBefore, heading);

      const lineBreakAfter = document.createElement("br");
      heading.parentNode.insertBefore(lineBreakAfter, heading.nextSibling);

      heading.style.fontWeight = "bold";
    });

    const h2s = tempElement.querySelectorAll("h2");
    const h3s = tempElement.querySelectorAll("h3");

    // Apply heading styles
    h2s.forEach((heading) => {
      heading.style.fontSize = "3rem";
    });

    // Apply heading styles
    h3s.forEach((heading) => {
      heading.style.fontSize = "2rem";
    });

    document.body.appendChild(tempElement);

    const range = document.createRange();
    range.selectNodeContents(tempElement);

    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);

    document.execCommand("copy");

    document.body.removeChild(tempElement);
    console.log("HTML copied to clipboard");
    setOpen(true);
    setCopied(true);
  };

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      if (copied) setCopied(false);
      setOpen(false);
    }, 1500);

    return () => clearTimeout(timeout);
  }, [copied]);

  const clippyStyles = {
    position: "absolute",
    top: 0,
    left: 0,
    strokeDasharray: 50,
    strokeDashoffset: copied ? -50 : 0,
    transition: "all 300ms ease-in-out",
  };

  const checkStyles = {
    position: "absolute",
    top: 0,
    left: 0,
    strokeDasharray: 50,
    strokeDashoffset: copied ? 0 : -50,
    transition: "all 300ms ease-in-out",
  };

  return (
    <div>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div>
          <Tooltip
            placement="top"
            arrow
            title="Copied!"
            PopperProps={{
              disablePortal: true,
              popperOptions: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -10],
                    },
                  },
                ],
              },
            }}
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            componentsProps={{
              tooltip: {
                sx: {
                  pointerEvents: "none",
                  fontFamily: "Proxima Nova",
                  padding: "4px 10px",
                  fontWeight: "normal",
                  fontSize: 13,
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
                  bgcolor: "rgba(0,0,0,1)",
                  "& .MuiTooltip-arrow": {
                    color: "#232323",
                  },
                },
              },
            }}
            disableInteractive
          >
            <button onClick={handleClick} className="py-2 px-2 hover:bg-gray-200/60 duration-200 -ml-2 rounded-md font-semibold inline-flex gap-x-2 items-center text-sm group duration-100" {...props} style={{ color: getSecondaryColor() }}>
              <div className="relative w-4 h-4">
                <Clippy style={clippyStyles} />
                <Check className="text-green-500" invisible={copied} style={checkStyles} />
              </div>
              <span className="group-hover:opacity-100">{label ? label : "Copy to clipboard"}</span>
            </button>
          </Tooltip>
        </div>
      </ClickAwayListener>
    </div>
  );
}

function Clippy(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" {...props}>
      <path d="M5.75 4.75H10.25V1.75H5.75V4.75Z" />
      <path d="M3.25 2.88379C2.9511 3.05669 2.75 3.37987 2.75 3.75001V13.25C2.75 13.8023 3.19772 14.25 3.75 14.25H12.25C12.8023 14.25 13.25 13.8023 13.25 13.25V3.75001C13.25 3.37987 13.0489 3.05669 12.75 2.88379" />
    </svg>
  );
}

function Check(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" {...props}>
      <path d="M13.25 4.75L6 12L2.75 8.75" />
    </svg>
  );
}
