import React, { useState, useRef, useLayoutEffect, useEffect, useMemo } from "react";

import SyntaxHighlighter from "react-syntax-highlighter";
import { agate } from "react-syntax-highlighter/dist/esm/styles/hljs";

import { useSettingsContext } from "../../utils/SettingsContext";
import { useRoutingContext } from "../../utils/RoutingContext";
import { useLocation } from "react-router-dom";

import { AnimatePresence, motion, useAnimation } from "framer-motion";
import { ChevronRightIcon, ArrowLeftIcon } from "@heroicons/react/20/solid";
import Avatar from "@mui/material/Avatar";
import { getContrastingColor, getTextColor } from "../../utils/colorFunctions";
import StyledTooltip from "../StyledToolTip/StyledToolTip";
import CopyClipboardIconButton from "../CopyClipboardButton/CopyClipboardIconButton";
import ImageGallery from "../ImageGallery/ImageGallery";
import { useReportContext } from "../../utils/ReportContext";
import CopyHTMLAsFormattedIconButton from "../CopyClipboardButton/CopyHTMLAsFormattedIconButton";
import JSZip from "jszip";
import ScrollableModal from "../ScrollableModal/ScrollableModal";
import { classNames } from '../../utils/utils';

const TRANSITION_DURATION_SLOW = 0.45; // TODO Move to utils aninations
const TRANSITION_DURATION_FAST = 0.2;

function roleIdToString(roleId) {
  switch (roleId) {
    case "topic_created_by":
      return "Topic Author";
    case "article_written_by":
      return "Article Author";
    case "article_quality_checked_by":
      return "Quality Control";
    default:
      return "";
  }
}

export default function ArticleDetails(props) {
  const location = useLocation();
  const [details, setDetails] = useState([]);
  const { settingsOpen, setSettingsOpen, getSecondaryColor, agencyName, imageServeUrl, displayWebsite, agencyWebsite } = useSettingsContext();
  const { setArticleId, setArticleDetailsView, dimensions, articleIndex } = useRoutingContext();
  const { reportData, displayWriterInfo, displayNotes, shareView, semRushData } = useReportContext();
  const [entry, setEntry] = useState(null);
  const [semRushStats, setSemRushStats] = useState({});
  const [images, setImages] = useState(null);
  const [isDownloading, setisDownloading] = useState(false);
  const [team, setTeam] = useState([]);
  const [teamModalOpen, setTeamModalOpen] = useState(false);

  // The ref and state below are used to get the destintion width of the headerCard animation
  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(null);
  const [containerHeight, setContainerHeight] = useState(null);

  // Article title 
  const articleTitle = entry?.topic.headline;
  const articleBody = entry?.article.body;

  // Combine the title and body for syntax highlighting
  const combinedHtml = `<h1>${articleTitle}</h1>
${articleBody}
  `;

  useEffect(() => {
    if (reportData.orderid) {
      setEntry(reportData.entries[articleIndex]);
      setSemRushStats(semRushData[articleIndex])
      setImages(reportData.entries[articleIndex].article.images);
      setTeam(reportData.entries[articleIndex].team.filter((member) => member.name !== "EditAI W."));
    }
  }, [reportData, articleIndex]);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (containerRef.current) {
      const width = containerRef.current.clientWidth;
      const height = displayWriterInfo ? "240px" : "auto";
      setContainerWidth(width);
      setContainerHeight(height);
    }
  }, [displayWriterInfo]);

  const animationControls = useAnimation();
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      y: 0,
      width: containerWidth != null ? containerWidth : "100%",
      height: containerHeight,
      transition: {
        staggerChildren: TRANSITION_DURATION_FAST,
        type: "tween",
        delayChildren: TRANSITION_DURATION_SLOW / 2,
      },
    },
  };

  useEffect(() => {
    animationControls.start({ x: 0, y: 0, width: containerWidth != null ? containerWidth : "100%", height: containerHeight, transition: { type: "tween", ease: "easeOut", duration: TRANSITION_DURATION_SLOW } });
    //setPlayAnimation(true);
  }, [animationControls, dimensions, containerRef]);

  const headerRef = useRef(null);

  async function generateAndDownloadZip(images) {
    const zip = new JSZip();
    const fetchPromises = [];

    setisDownloading(true);

    // Create an array of fetch promises to fetch the image data as Blobs
    images.forEach((image, index) => {
      fetchPromises.push(
        fetch(image.url)
          .then((response) => response.blob())
          .then((blob) => {
            // Add the image file to the zip
            zip.file(`image_${index}.jpg`, blob);
          })
      );
    });

    // Wait for all the fetch promises to resolve
    await Promise.all(fetchPromises);

    setisDownloading(false);

    // Generate the zip file and initiate the download
    zip.generateAsync({ type: "blob" }).then((blob) => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "images.zip"; // Set the desired filename for the zip file
      link.click();
    });
  }

  function handleDownloadImages() {
    if (images.length <= 1) {
      // If there's only one image, download it directly
      const image = images[0];
      const link = document.createElement("a");
      link.href = image.url;
      link.download = image.title; // Set the desired filename for the download
      link.click();
    } else {
      // If there are multiple images, generate and download as a zip file
      generateAndDownloadZip(images);
    }
  }

  return (
    <div className="-ml-6 -mr-6 -mt-4 pt-4 pb-6 px-6 bg-gray-50/80 rounded-2xl">

      {teamModalOpen &&
        <ScrollableModal title={
          <div className="flex items-center gap-x-2">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 text-gray-500 mr-2">
              <path d="M4.5 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM14.25 8.625a3.375 3.375 0 116.75 0 3.375 3.375 0 01-6.75 0zM1.5 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM17.25 19.128l-.001.144a2.25 2.25 0 01-.233.96 10.088 10.088 0 005.06-1.01.75.75 0 00.42-.643 4.875 4.875 0 00-6.957-4.611 8.586 8.586 0 011.71 5.157v.003z" />
            </svg>
            <h2>Meet the Content Team</h2>
          </div>
        } onClose={() => setTeamModalOpen(false)}>
          <TeamModalContent team={team} />
        </ScrollableModal>
      }

      {/* Top container with Logo and action buttons */}
      {!shareView &&
          <div ref={containerRef} className="px-4 py-5 sm:px-6">
        <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
          <div className="ml-4 mt-4">
            <div className="flex items-center gap-x-2 font-semibold text-lg">
              {/* Default Branded: HOTH logo */}
              {!settingsOpen ? (
                  <div>
                    <img className="h-10"
                         src={(process.env.REACT_APP_VERCEL_URL ? 'https://' + process.env.REACT_APP_VERCEL_URL : '') + "/images/thehoth-logo.svg"}
                         alt="The HOTH logo"/>
                  </div>
              ) : (
                  <div className="flex items-center gap-x-3">
                    {/* If white label (settings open): Client logo & name or placeholder */}
                    {!imageServeUrl ? (
                        <div className="bg-gray-200 h-12 w-12 rounded-full grid place-items-center">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                               className="w-6 h-6 text-white opacity-80">
                            <path fillRule="evenodd"
                                  d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                                  clipRule="evenodd"/>
                          </svg>
                        </div>
                    ) : (
                        <>
                          <Avatar src={imageServeUrl} aria-label="logo avatar"/>
                        </>
                    )}
                    {!agencyName ? (
                        <div>
                          <StyledTooltip arrow title="To edit your logo, please use the side panel.">
                            <span className="py-1">Company Name</span>
                          </StyledTooltip>
                        </div>
                    ) : (
                        <span>{agencyName}</span>
                    )}
                  </div>
              )}
            </div>
            <div className="text-sm font-semibold ml-2 mt-1">{(displayWebsite && shareView) ? agencyWebsite : null}</div>
          </div>
          {!settingsOpen && (
              <div className="ml-4 mt-4 flex flex-shrink-0">
                <button type="button"
                        className="relative inline-flex items-center rounded-md px-5 py-2.5 text-sm font-semibold shadow-sm ring-0 ring-inset ring-gray-300 bg-zinc-700/20 duration-200 hover:bg-zinc-700/30">
                  <span>Contact Support</span>
                </button>
                <button onClick={() => setSettingsOpen(true)} type="button"
                        className="relative ml-3 bg-red-600 text-white hover:bg-red-600 duration-200 inline-flex items-center hover:shadow-lg opacity-90 rounded-md px-5 py-2.5 text-sm font-semibold shadow-sm ring-0 ring-inset ring-gray-300 duration-200">
                  <span>Customize & Share</span>
                </button>
              </div>
          )}
        </div>
      </div>}

      {/* Header card */}

      <motion.div className="header-details-card-wrapper" ref={headerRef} initial={{ backgroundColor: "transparent", opacity: 1, x: 0, y: dimensions.top - 105, width: dimensions.width, height: dimensions.height }} animate={animationControls}>
        <motion.section variants={container} initial="hidden" animate="show" className="article-header relative max-w-full rounded-lg z-40 bg-gray-100 border border-gray-200/0 border-1 rounded-lg h-full">
          {/* Title + Meta */}

          <div className="flex-1 px-4 py-5 sm:p-6 flex flex-col">
            {/* Breadcrumbs */}
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { type: "tween", duration: 0.2, delay: TRANSITION_DURATION_SLOW * 0.9 } }}>
              <nav className="flex mb-2 mt-1" aria-label="Breadcrumb">
                <ol className="flex items-center space-x-1">
                  <li>
                    <div
                      className="flex items-center"
                      onClick={() => {
                        setArticleDetailsView(false);
                        setArticleId(null);
                      }}
                    >
                      <div className="flex text-sm font-medium text-gray-500 hover:text-gray-700 cursor-pointer"><ArrowLeftIcon className="h-4 w-4 flex-shrink-0 text-gray-400 self-center mr-1" aria-hidden="true" />Overview</div>
                    </div>
                  </li>
                  <li>
                    <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                  </li>
                  <li>
                    <div className="flex items-center">
                      <div style={{ color: getSecondaryColor(), opacity: 1 }} className="text-sm font-semibold text-blue-500">
                        Article {articleIndex + 1}
                      </div>
                    </div>
                  </li>
                </ol>
              </nav>
            </motion.div>

            <div className="flex flex-1 justify-between items-start grow">
              <div className="flex flex-col min-h-9">
                <div className="flex items-center gap-x-4">
                  <h1 className="font-bold font-gray-900 text-xl min-h-[32px] lg:text-3xl">{entry?.topic.headline}</h1>
                </div>
              </div>
            </div>

            {/* Writers */}
            {displayWriterInfo && team.length > 0 && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { type: "tween", delay: TRANSITION_DURATION_SLOW * 0.8 } }}
              >
                <dl
                  className="flex -ml-3 flex-wrap gap-x-8 mt-4 group cursor-pointer"
                  onClick={() => setTeamModalOpen(true)}
                >
                  {team ? (
                    <>
                      {team
                        .map((member) => (
                          <div
                            key={member.name}
                            className={classNames(roleIdToString(member.role) === "Topic Author" && "order-first", "relative overflow-hidden p-3 group-hover:bg-gray-200/60 duration-200 rounded-lg pt-2 mt-4 flex gap-x-3")}
                          >
                            <img
                              className="inline-block h-10 w-10 rounded-full"
                              src={member.image}
                              alt={member.name}
                            />
                            <div>
                              <dt>
                                <p className="text-xs font-semibold leading-6 truncate opacity-50">{roleIdToString(member.role)}</p>
                              </dt>
                              <dd className="-mt-1 flex items-baseline gap-x-2">
                                <span className="text-md font-semibold">{member.name}</span>
                              </dd>
                            </div>
                          </div>
                        ))
                      }
                    </>
                  ) : null}
                </dl>
              </motion.div>
            )}
          </div>
        </motion.section>
      </motion.div>

      <motion.div>
        <div className="">
          <div className="min-h-full">
            {entry && (
              <motion.main initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { type: "tween", delay: 0.3 } }} className="pb-4 pt-8 pb-20 space-y-12">
                {/* Main Description section */}
                <section className="mx-auto gap-6 max-w-7xl">
                  <SectionTitle style={{ color: getSecondaryColor(), opacity: 1 }}>Meta Description</SectionTitle>
                  <div className="mt-4 grid max-w-4xl grid-cols-1 gap-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                    <div className="space-y-6 lg:col-span-2 lg:col-start-1 text-gray-600">{entry?.topic.seo_description}</div>
                    <div aria-labelledby="main-description-info-panel" className="lg:col-span-1 lg:col-start-3">
                      <InfoPanel>You can add this in the SEO snippet box on your blog.</InfoPanel>
                    </div>
                  </div>
                </section>

                {/* Targeted Keywords section */}
                <section className="mx-auto gap-6 max-w-7xl">
                  <SectionTitle style={{ color: getSecondaryColor(), opacity: 1 }}> Targeted Keywords</SectionTitle>
                  <div className="mt-4 grid max-w-4xl grid-cols-1 gap-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                    <div className="space-y-6 lg:col-span-2 lg:col-start-1">
                      <p className="text-gray-600">{entry?.topic.keywords}</p>

                      {/* Stats */}
                      <div className="flex flex-wrap gap-x-10 mt-8">
                        {semRushStats && semRushStats.search_volume &&
                            <div className="">
                              <p className="text-sm font-medium leading-6 text-gray-400">Search Volume</p>
                              <p className="mt-1 flex items-baseline gap-x-2">
                                <span className="text-2xl font-bold tracking-tight text-gray-900">{semRushStats.search_volume}</span>
                              </p>
                            </div>
                        }
                        {semRushStats && semRushStats.keyword_difficulty_index &&
                            <div className="">
                              <p className="text-sm font-medium leading-6 text-gray-400">Keyword Diff</p>
                              <p className="mt-1 flex items-baseline gap-x-2">
                                <span className="text-2xl font-bold tracking-tight text-gray-900">{semRushStats.keyword_difficulty_index}</span>
                              </p>
                            </div>
                        }
                      </div>
                    </div>

                    <div aria-labelledby="targeted-keywords-info-panel" className="lg:col-span-1 lg:col-start-3">
                      <InfoPanel>
                        <p>
                          <b className="text-gray-900">Search volume</b> is the number of times a specific keyword is searched for in a specific time frame.
                        </p>
                        <p className="mt-3">
                          <b className="text-gray-900">Keyword difficulty</b> is a metric that measures the effort it would take for your content to rank on the first page of Google for a certain keyword.
                        </p>
                        <small className="text-xs font-medium text-gray-500/70 mt-4 block italic">Metrics provided by SEMRush</small>
                      </InfoPanel>
                    </div>
                    </div>
                </section>

                {/* Images Section */}
                {images && images.length > 0 && (
                  <section className="mx-auto gap-6 max-w-7xl">
                    <SectionTitle style={{ color: getSecondaryColor(), opacity: 1 }}>Images</SectionTitle>
                    <div className="mt-4 grid max-w-4xl grid-cols-1 gap-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                      {entry && <ImageGallery images={images} />}

                      <div aria-labelledby="images-info-panel" className="lg:col-span-1 lg:col-start-3">
                        <InfoPanel>
                          <p>Download all the images to your local computer and upload in the right spots.</p>
                          <button onClick={handleDownloadImages} style={{ color: getSecondaryColor() }} type="button" className="inline-flex mt-3 -ml-2 items-center gap-x-1.5 rounded-md px-3 duration-100 hover:bg-gray-200/50 py-2 text-sm font-semibold text-blue-500 duration-200 group hover:text-blue-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-40 disabled:bg-gray-200 disabled:text-gray-500 disabled:cursor-not-allowed" disabled={isDownloading}>
                            {isDownloading ? (
                              <svg class="animate-spin h-5 w-5 text-gray-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                              </svg>
                            ) : (
                              <svg style={{ color: getSecondaryColor(), opacity: 1 }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-blue-500 duration-200 group-hover:text-blue-900">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                              </svg>
                            )}
                            Download Images
                          </button>
                        </InfoPanel>
                      </div>
                    </div>
                  </section>
                )}

                {/* Formatted Article Content Section */}
                <section className="mx-auto gap-6 max-w-7xl">
                  <SectionTitle style={{ color: getSecondaryColor(), opacity: 1 }}>Formatted Article Content</SectionTitle>
                  <div className="mt-4 grid max-w-4xl grid-cols-1 gap-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                    <div className="space-y-6 lg:col-span-2 lg:col-start-1">
                      {/* Formatted aricle body */}

                      <div className="formatted-article-body rounded-md text-gray-600 text-md overflow-hidden bg-gray-100 px-6 py-6">
                        <h1 className="font-bold font-gray-900 text-xl pb-8 lg:text-2xl">{entry?.topic.headline}</h1>
                        {entry && <div dangerouslySetInnerHTML={{ __html: entry.article.body }}></div>}</div>
                    </div>
                    <div aria-labelledby="formatted-article-content-info-panel" className="lg:col-span-1 lg:col-start-3">
                      <InfoPanel>
                        <p className="text-sm text-gray-500 mb-2">This is the article with formatted headings, images, and body text.</p>
                        <CopyHTMLAsFormattedIconButton html={entry.article.body} label="Copy article to clipboard" />
                      </InfoPanel>
                    </div>
                  </div>
                </section>

                {/* HTML Article Content Section */}
                <section className="mx-auto gap-6 max-w-7xl">
                  <SectionTitle style={{ color: getSecondaryColor(), opacity: 1 }}>HTML Article Content</SectionTitle>
                  <div className="mt-4 grid max-w-4xl grid-cols-1 gap-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                    <div className="space-y-6 lg:col-span-2 lg:col-start-1">
                      <div className="syntaxhighlighter rounded-md overflow-hidden">
                        <SyntaxHighlighter language="htmlbars" style={agate} showLineNumbers>
                          {combinedHtml}
                        </SyntaxHighlighter>
                      </div>
                    </div>
                    <div aria-labelledby="html-article-content-info-panel" className="lg:col-span-1 lg:col-start-3">
                      <InfoPanel>
                        <p className="text-sm text-gray-500 mb-2">This is the raw HTML code containing your article content. Use the button above to copy to clipboard.</p>
                        <CopyClipboardIconButton content={combinedHtml} label="Copy HTML to clipboard" />
                      </InfoPanel>
                    </div>
                  </div>
                </section>
              </motion.main>
            )}
          </div>
        </div>
      </motion.div>
    </div>
  );
}

const SectionTitle = ({ children, style }) => {
  return (
    <div className="relative">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-100" />
      </div>
      <div className="relative flex justify-start">
        <span style={style} className="bgray-50 pr-2 font-semibold text-sm text-blue-500">
          {children}
        </span>
      </div>
    </div>
  );
};

const InfoPanel = ({ children }) => {
  return (
    <div className="overflow-hidden rounded-md bg-gray-100">
      <div className="flex items-start gap-x-3 px-4 py-5 sm:p-6">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-gray-400">
            <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
          </svg>
        </div>
        <div className="text-sm text-gray-500">{children}</div>
      </div>
    </div>
  );
};

function TeamModalContent({ team }) {
  return (

    <div className="mx-auto max-w-lg gap-x-8 gap-y-20 max-md:pb-8">
      <ul role="list" className="max-md:divide-y flex flex-col divide-gray-200 xl:col-span-3 -mt-4 mr-4">
        {team ? (
          <>
            {team.map((member) => (
              <li key={member.name} className={classNames(roleIdToString(member.role) === "Topic Author" && "order-first", "mb-6 first:mt-0 flex text-left flex-col items-start gap-4 lg:gap-6 pt-8 lg:pt-6 sm:flex-row")}>

                <img className="w-16 w-16 flex-none rounded-full object-cover" src={member.image} alt={member.name} />

                <div className="max-w-xl flex-auto">
                  <h3 className="text-lg font-semibold leading-8 tracking-tight text-gray-800">{member.name}</h3>
                  <p className="text-base leading-7 text-gray-600">{roleIdToString(member.role)}</p>
                  <p className="mt-4 leading-normal text-sm text-gray-500">{member.bio}</p>
                </div>
              </li>
            ))}
          </>
        ) : null}
      </ul>
    </div>

  )
}
