import React, { useState, useEffect } from "react";

import { useReportContext } from "../../utils/ReportContext";
import { useSettingsContext } from "../../utils/SettingsContext";

import { classNames } from "../../utils/utils";
import { PhoneIcon } from "@heroicons/react/20/solid";
import Avatar from "@mui/material/Avatar";
import { adjustColorBrightness, getOppositeColorFromText, getTextColor } from "../../utils/colorFunctions";
import StyledTooltip from "../StyledToolTip/StyledToolTip";
import { AnimatePresence, motion } from "framer-motion";

export default function HeaderCard(props) {
  const { reportData, isLoading, shareView, reportType } = useReportContext();
  const [details, setDetails] = useState([]);
  const { settingsOpen, setSettingsOpen, bgColor, getBgColor, agencyName, imageServeUrl, displayWebsite, agencyWebsite, displayAddress, agencyAddress, displayPhone, agencyPhone, clientName } = useSettingsContext();

  // console.log('reportData', reportData);
  useEffect(() => {
    let data;
    if (reportData.orderid) {
      let wordcount;

      if (reportData.controller === "hothblogger") {
        wordcount = reportData.packageName.split("-")[1].trim().split(" ")[0];
      } else {
        const numberPattern = /\d+/;
        const extractedNumber = reportData.packageName.match(numberPattern)[0];
        wordcount = extractedNumber;
      }

      data = [
        {
          title: "Order ID",
          value: reportData.orderid,
        },
        {
          title: "Total Articles",
          value: reportData.totalArticles,
        },
        {
          title: "Total Pages",
          value: reportData.items?.length,
        },
        {
          title: "Word Count",
          value: wordcount, //reportData.packageName.split("-")[1].trim().split(" ")[0],
        },
      ];
    }
    setDetails(data);
  }, [reportData]);

  return (
    <>
      <div className="mx-auto max-w-7xl">
        <div style={{ backgroundColor: getBgColor(), opacity: 1 }} className={classNames(getTextColor(bgColor) === "#ffffff" ? "divide-white/30" : "divide-black/10", "divide-y mx-auto max-w-7xl rounded-md shadow")}>
          {/* Header */}
          <div className="px-4 py-5 sm:px-6">
            <div className="-ml-4 -mt-4 flex flex-wrap items-start justify-between sm:flex-nowrap">
              <div className="ml-4 mt-4">
                <div className="flex items-center gap-x-2 font-semibold text-lg">
                  {/* Default Branded: HOTH logo */}
                  {!settingsOpen && !shareView ? (
                    <div>
                      <img className="h-10" src={(process.env.REACT_APP_VERCEL_URL ? 'https://' + process.env.REACT_APP_VERCEL_URL : '') + "/images/thehoth-logo--white.svg"} alt="The HOTH logo" />
                    </div>
                  ) : (
                    <div className="flex items-center gap-x-3">
                      {/* If white label (settings open): Client logo & name or placeholder */}
                      {!imageServeUrl && !shareView ? (
                        <div className="bg-gray-400/50 h-12 w-12 rounded-full grid place-items-center">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 text-white opacity-80">
                            <path fillRule="evenodd" d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z" clipRule="evenodd" />
                          </svg>
                        </div>
                      ) : (
                        <>
                          {imageServeUrl && (shareView || settingsOpen) && <Avatar src={imageServeUrl} aria-label="logo avatar" />}
                          {!imageServeUrl && (shareView || settingsOpen) && <></>}
                        </>
                      )}
                      {!agencyName && !shareView && (
                        <div>
                          <StyledTooltip arrow title="To edit your logo, please use the side panel.">
                            <span className="py-1" style={{ color: getTextColor(bgColor), opacity: 1 }}>
                              Company Name
                            </span>
                          </StyledTooltip>
                        </div>
                      )}
                      {!agencyName && shareView && <span style={{color: getTextColor(bgColor), opacity: 1}}>{`Report for Order #${reportData.orderid}`}</span>}
                      {agencyName && <span style={{color: getTextColor(bgColor), opacity: 1}}>{agencyName}</span>}

                    </div>
                  )}
                </div>

                {(shareView || settingsOpen) && (agencyWebsite !== "" || agencyAddress !== "" || agencyPhone !== "") ? (
                  <>
                    <div style={{ color: settingsOpen || shareView ? getTextColor(bgColor) : "#ffffff", opacity: 0.7 }} className={classNames(displayWebsite || displayAddress || displayPhone ? "mt-4" : "", "text-sm font-semibold ml-2 flex space-x-4 ")}>
                      {displayWebsite && agencyWebsite !== "" && (
                        <>
                          <div className="inline-block"><a href={`https://${agencyWebsite}`} target="_blank" rel="noreferrer">{`https://${agencyWebsite}`}</a></div>
                          <div className="inline-block mx-2 pl-1 opacity-70 last:hidden">•</div>
                        </>
                      )}
                      {displayAddress && agencyAddress !== "" && (
                        <>
                          <div className="inline-block">{agencyAddress}</div>
                          <div className="inline-block mx-2 pl-1 opacity-70 last:hidden">•</div>
                        </>
                      )}
                      {displayPhone && agencyPhone !== "" && (
                        <div className="flex items-center">
                          <PhoneIcon className="h-4 w-4 mr-1 -mb-0.5 opacity-50" />
                          <div>{agencyPhone}</div>
                        </div>
                      )}
                    </div>
                  </>
                ) : null}
              </div>
              {!settingsOpen && !shareView && (
                <div className="ml-4 mt-4 flex flex-shrink-0">
                  {/* link to thehoth.com/ticket */}
                  <a href="https://www.thehoth.com/ticket" target="_blank" rel="noreferrer">
                    <button style={{ border: "1px solid", borderColor: getTextColor(bgColor) === "#ffffff" ? "rgba(255,255,255,0.4)" : "rgba(0,0,0,0.2)", color: getTextColor(bgColor) }} type="button" className="relative inline-flex items-center rounded-md px-5 py-2.5 text-sm font-semibold shadow-sm ring-0 ring-inset ring-gray-300 bg-zinc-700/20 duration-200 hover:bg-zinc-700/30">
                      <span style={{ color: settingsOpen || shareView ? getTextColor(bgColor) : "#ffffff", opacity: 0.9 }}>Contact Support</span>
                    </button>
                  </a>
                  <button onClick={() => setSettingsOpen(true)} style={{ backgroundColor: settingsOpen ? adjustColorBrightness(getTextColor(bgColor), 1) : "#E22D1B", color: settingsOpen ? getOppositeColorFromText(bgColor) : "white" }} type="button" className="relative ml-3 inline-flex items-center rounded-md px-5 py-2.5 text-sm font-semibold shadow-sm ring-0 ring-inset ring-gray-300 duration-200">
                    <span>Customize & Share</span>
                  </button>
                </div>
              )}
            </div>
          </div>
          {/* Title + Meta */}
          <div className="px-4 py-5 sm:p-6">
            <div className="flex justify-between items-start">
              <div className="flex flex-col min-h-9">
                <h1 style={{ color: settingsOpen ? getTextColor(bgColor) : "#ffffff" }} className="font-bold text-xl min-h-[32px] lg:text-3xl">
                  {isLoading ? <div className="skeleton-article__title animate-pulse h-9 w-64 bg-slate-500 rounded-full opacity-10"></div> :
                      <>
                        {reportData.productName && reportData.productName === "HOTH Blogger" && (!shareView && !settingsOpen) && <>Blogger Order Report</>}
                        {reportData.productName && reportData.productName === "HOTH Blogger" && (shareView || settingsOpen) && <>Blog Content Order Report</>}
                        {reportData.productName && reportData.productName === "AI Content Plus" && (!shareView && !settingsOpen) && <>AI Content Plus Order Report</>}
                        {reportData.productName && reportData.productName === "AI Content Plus" && (shareView || settingsOpen) && <>AI Blog Content Order Report</>}
                        {reportData.productName && reportData.productName === "HOTH Web Copy" && <>Web Copy Order Report</>}
                      </>}
                </h1>
                {!isLoading && reportType === 'hothblogger' && (
                  <p className="mt-3 text-md" style={{ color: settingsOpen || shareView ? getTextColor(bgColor) : "#ffffff", opacity: 0.8 }}>
                    For{" "}
                    <a href={reportData.targetUrl} target="_blank" rel="noreferrer" className="hover:underline">
                      {reportData.targetUrl}
                    </a>
                  </p>
                )}
                {isLoading && (
                  <div className="mt-3 text-md" style={{ color: settingsOpen || shareView ? getTextColor(bgColor) : "#ffffff", opacity: 0.8 }}>
                    <div className="skeleton__for-url animate-pulse h-4 my-1 w-80 bg-slate-500 rounded-full opacity-10"></div>
                  </div>
                )}
              </div>
              <div style={{ color: settingsOpen || shareView ? getTextColor(bgColor) : "#ffffff", opacity: 0.6 }} className="text-sm">
                {reportData.productName && <> Created {reportData.reportDate.slice(0, 10)}</>}
              </div>
            </div>

            <div className="flex flex-col space-y-2 mt-4">
              {clientName && (shareView || settingsOpen) ? (
                <p style={{ color: settingsOpen || shareView ? getTextColor(bgColor) : "#ffffff", opacity: 0.7 }} className="font-sans">
                  {clientName}
                </p>
              ) : null}
            </div>

            {/* Stats */}
            <dl className="flex flex-wrap gap-x-10 mt-4">
              {details ? (
                <>
                  {details.map((d) => (
                    <div key={d.title} className={classNames(d.value || d.value === 0 ? "" : "hidden", "relative overflow-hidden rounded-lg pt-2 pb-1 mt-4")}>
                      <dt>
                        <p style={{ color: settingsOpen || shareView ? getTextColor(bgColor) : null, opacity: settingsOpen ? 0.5 : 1 }} className="text-xs font-semibold leading-6 truncate text-blue-500">
                          {d.title}
                        </p>
                      </dt>
                      <dd className="mt-0.5 flex items-baseline gap-x-2">
                        {isLoading ? (
                          <div className="skeleton-article__title animate-pulse h-7 w-16 bg-slate-500 rounded-full opacity-10"></div>
                        ) : (
                          <span style={{ color: settingsOpen || shareView ? getTextColor(bgColor) : "#ffffff", opacity: 1 }} className="text-xl font-semibold">
                            {d.value}
                          </span>
                        )}
                      </dd>
                    </div>
                  ))}
                </>
              ) : null}
            </dl>
          </div>
        </div>
      </div>
    </>
  );
}
