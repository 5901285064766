import React, { useEffect, useRef } from "react";
import { PhotoIcon } from "@heroicons/react/24/solid";
import { useSettingsContext } from "../../utils/SettingsContext";
import CircularProgress from "@mui/material/CircularProgress";
import StyledTooltip from "../StyledToolTip/StyledToolTip";

import { UserCircleIcon } from "@heroicons/react/20/solid";
import { toast } from "react-toastify";

export default function ImageUploadField() {
  const { uploadUrl, setUploadUrl, setImageId, imageServeUrl, setImageServeUrl, fileUpload, setFileUpload } = useSettingsContext();
  const statusRef = useRef();
  const loadTotalRef = useRef();
  const [progress, setProgress] = React.useState(0);

  useEffect(() => {
    prepForImageUpload();
    // eslint-disable-next-line
  }, []);

  const prepForImageUpload = async () => {
    const workerRequest = await fetch("https://campaign-report-images-cfworker.nnm.workers.dev", {
      method: "GET",
    });
    const response = await workerRequest.json();
    const { uploadURL, id } = response.result;

    setUploadUrl(uploadURL);
    setImageId(id);
  };

  const saveFile = async () => {
    //removeFile();

    setFileUpload(document.querySelector("#file-upload").files[0].name);

    if (document.getElementById("file-upload").files.length > 0) {
      const formData = new FormData();
      formData.set("file", document.getElementById("file-upload").files[0], document.getElementById("file-upload").files[0].name);

      const xhr = new XMLHttpRequest();

      xhr.upload.addEventListener("progress", ProgressHandler, false);
      xhr.addEventListener("load", SuccessHandler, false);
      xhr.addEventListener("error", ErrorHandler, false);
      xhr.addEventListener("abort", AbortHandler, false);
      xhr.open("POST", uploadUrl);

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          const info = JSON.parse(xhr.responseText);
          const { variants } = info.result;

          setImageServeUrl(variants[0]);
          // settings.imageServeUrl = variants[0];
          // settings.imageId = imageId;
        }
      };

      xhr.send(formData);
    }
  };

  const removeFile = () => {
    setFileUpload(null);
    document.getElementById("file-upload").value = null;
    setImageServeUrl(null);
    prepForImageUpload();
  };

  const ProgressHandler = (e) => {
    loadTotalRef.current.innerHTML = `uploaded ${e.loaded} bytes of ${e.total}`;
    var percent = (e.loaded / e.total) * 100;
    statusRef.current.innerHTML = Math.round(percent) + "% uploaded...";
    setProgress(percent);
  };

  const SuccessHandler = (e) => {
    statusRef.current.innerHTML = e.target.responseText;
    setProgress(0);
  };
  const ErrorHandler = () => {
    statusRef.current.innerHTML = "upload failed!!";
  };
  const AbortHandler = () => {
    statusRef.current.innerHTML = "upload aborted!!";
  };

  return (
    <>
      <form id="image-upload-form" encType="multipart/form-data">
        <div className="block">
          <div className="App">
            <p className="hidden" ref={statusRef}></p>
            <p className="hidden" ref={loadTotalRef}></p>
          </div>

          <label htmlFor="agency-logo" className="block text-sm font-semibold leading-6 text-gray-900 flex gap-x-0.5 items-center">
            <div className="inline-block">Agency Logo</div>
            <StyledTooltip placement="right" arrow title="Recommended size: 64x64">
              <div className="px-1 py-0.5 cursor-help">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 text-gray-400">
                  <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm11.378-3.917c-.89-.777-2.366-.777-3.255 0a.75.75 0 01-.988-1.129c1.454-1.272 3.776-1.272 5.23 0 1.513 1.324 1.513 3.518 0 4.842a3.75 3.75 0 01-.837.552c-.676.328-1.028.774-1.028 1.152v.75a.75.75 0 01-1.5 0v-.75c0-1.279 1.06-2.107 1.875-2.502.182-.088.351-.199.503-.331.83-.727.83-1.857 0-2.584zM12 18a.75.75 0 100-1.5.75.75 0 000 1.5z" clipRule="evenodd" />
                </svg>
              </div>
            </StyledTooltip>
          </label>
          <p className="text-xs leading-5 text-gray-500">PNG, SVG, JPG, GIF up to 10MB</p>

          <div className="mt-2 flex items-center gap-x-3 mb-6">
            <div className="relative h-16 inline-block w-16 bg-gray-200 rounded-full">
              {imageServeUrl && <img src={imageServeUrl} alt="" className="h-16 w-16 border border-gray-300/10 pointer-events-none object-fit-cover rounded-full absolute" />}
              {!imageServeUrl && (
                <div className="rounded-full absolute z-10 bg-gray-200 h-16 w-16 grid place-items-center">
                  <PhotoIcon className="h-8 w-8 text-white opacity-90" aria-hidden="true" />
                </div>
              )}
              {imageServeUrl && (
                <StyledTooltip title="Remove">
                  <button className="absolute -top-0.5 -right-0.5 z-20 bg-white hover:bg-white hover:scale-[1.1] duration-200 cursor-pointer hover:shadow-lg shadow rounded-full h-6 w-6 grid place-items-center" onClick={removeFile}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                      <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                    </svg>
                  </button>
                </StyledTooltip>
              )}

              {progress !== 0 && (
                <CircularProgress
                  size={64}
                  sx={{
                    color: "#1895FD",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: 11,
                  }}
                  variant="determinate"
                  value={progress}
                />
              )}
            </div>
            <input id="file-upload" name="file-upload" type="file" className="sr-only" onChange={saveFile} />
            <div>
              <label htmlFor="file-upload" className="relative rounded-md inline-block mt-1 bg-white px-4 cursor-pointer py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                {imageServeUrl ? "Change" : "Upload a file"}
              </label>
            </div>
          </div>
        </div>
      </form>

      <div className="hidden">
        {fileUpload ? (
          <div className="flex justify-center items-center">
            <p className="text-xs leading-5 text-gray-600">{fileUpload}</p>
          </div>
        ) : null}
      </div>
      <div className="w-full border-t border-gray-300 my-4" />
    </>
  );
}
