// PreviewReport.js
import React from "react";
import BloggerReport from "./BloggerReport";

export default function PreviewReport() {
  // Read customization settings from local storage or query parameters
  const customizationSettings = "...";

  return <BloggerReport previewMode={true} customizationSettings={customizationSettings} />;
}
