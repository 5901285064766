import React from "react";
import { Switch } from "@headlessui/react";
import { classNames } from "../../utils/utils";

export default function SwitchToggle(props) {
  const { title, state, setState } = props;

  return (
    <Switch.Group as="div" className="flex items-center justify-between py-2">
      <span className="flex flex-grow flex-col">
        <Switch.Label as="span" className="text-sm font-semibold leading-6 text-gray-900" passive>
          {title}
        </Switch.Label>
      </span>
      <Switch checked={state} onChange={setState} className={classNames(state ? "bg-blue-500" : "bg-gray-200", "relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-0 focus:ring-blue-500 focus:ring-offset-0")}>
        <span aria-hidden="true" className={classNames(state ? "translate-x-5" : "translate-x-0", "pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out")} />
      </Switch>
    </Switch.Group>
  );
}
