import React, { useState } from "react";

import { useSettingsContext } from "../../utils/SettingsContext";
import { CloudArrowDownIcon, EllipsisVerticalIcon, TrashIcon } from "@heroicons/react/20/solid";
import StyledTooltip from "../StyledToolTip/StyledToolTip";
import { motion, AnimatePresence } from "framer-motion";

export default function ImageGallery({ images }) {
  const [galleryImages, setGalleryImages] = useState(images);

  function handleImageRemove(image) {
    setGalleryImages((prevImages) => prevImages.filter((img) => img !== image));
  }

  return (
    <AnimatePresence initial={false}>
      <ul className="grid lg:col-span-2 lg:col-start-1 grid-cols-2 gap-x-4 gap-y-6 sm:grid-cols-3 sm:gap-x-4 lg:grid-cols-4 xl:gap-x-6">
        {galleryImages.map((image, index) => (
          <motion.li key={index} className="relative" initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0, delay: index * 0, ease: "easeOut" }}>
            <ImageGalleryEntry image={image} onRemove={() => handleImageRemove(image)} />
          </motion.li>
        ))}
        <li className="hidden grid place-items-center h-48 border-gray-200 outline-dashed outline-gray-200 rounded-md cursor-pointer hover:bg-gray-100/40 hover:outline-gray-300 duration-300">
          <div className="add-image-button-content text-center">
            <div className="flex items-center text-sm text-gray-600">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
              </svg>
              Add Image
            </div>
            <div className="text-xs text-gray-400 mt-2">10Mb max.</div>
          </div>
        </li>
      </ul>
    </AnimatePresence>
  );
}

function ImageGalleryEntry({ image, onRemove }) {
  const [lightboxOpen, setLightboxOpen] = useState(false);

  function handleDownloadClick(event) {
    event.stopPropagation();
    const link = document.createElement("a");
    link.href = image.url;
    link.download = image.title; // Set the desired filename for the download
    link.click();
  }

  function handleRemoveClick(event) {
    event.stopPropagation();
    onRemove(image);
  }

  function handleImageClick(event) {
    event.stopPropagation();
    setLightboxOpen(true);
  }

  function handleCloseLightbox() {
    setLightboxOpen(false);
  }

  return (
    <div className="relative group block w-full h-48 overflow-hidden rounded-md bg-gray-900">
      {lightboxOpen && (
        <div onClick={handleCloseLightbox} className="fixed backdrop-blur top-0 left-0 w-full h-full flex p-8 justify-center items-center z-50 bg-black bg-opacity-75">
          <img src={image.url} alt={image.title} className="max-w-full max-h-full cursor-pointer" />
          <div className="max-w-lg ml-6">
            <div className="text-md font-bold text-white">Attribution</div>
            <hr className="mb-2"></hr>
            <p className="text-md text-white">{image.attribution}</p>
          </div>
          <button onClick={handleCloseLightbox} className="absolute top-2 right-2 duration-200 text-white/70 hover:text-white">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      )}
      <div className="absolute top-2 right-2 flex gap-x-1 opacity-0 pointer-events-none group-hover:opacity-100 z-10 group-hover:pointer-events-auto hover-actions-container">
        <StyledTooltip placement="top" title="Download">
          <button onClick={handleDownloadClick} className="rounded-md h-8 w-8 grid bg-white/90 hover:bg-white text-white place-items-center cursor-pointer">
            <CloudArrowDownIcon className="h-4 w-4 text-gray-900" />
          </button>
        </StyledTooltip>
        <StyledTooltip placement="top" title="Remove">
          <button onClick={handleRemoveClick} className="grid hidden place-items-center rounded-md bg-white/90 hover:bg-white hover:text-red-600 w-8 h-8 duration-100 cursor-pointer">
            <TrashIcon className="h-4 w-4" />
          </button>
        </StyledTooltip>
      </div>
      <img src={image.url} alt="" className="pointer-events-none object-cover absolute top-0 left-0 h-full w-full group-hover:opacity-95 duration-100" />
      <button type="button" className="absolute inset-0 focus:outline-none" onClick={handleImageClick}>
        <span className="sr-only">View details for {image.title}</span>
      </button>
    </div>
  );
}
