import { useSettingsContext } from "../../utils/SettingsContext";
import React, { useState, useEffect } from "react";
import { useReportContext } from "../../utils/ReportContext";

const statuses = {
  offline: "text-gray-500 bg-gray-100/10",
  online: "text-green-400 bg-green-400/10",
  error: "text-rose-400 bg-rose-400/10",
};
const environments = {
  Preview: "text-gray-400 bg-gray-600/30 ring-gray-700",
  Production: "text-indigo-400 bg-indigo-400/10 ring-indigo-400/30",
};
const deployments = [
  {
    id: 1,
    href: "#",
    productName: "Blogger",
    articleCount: "5 articles",
    wordCount: "1,000 words",
    statusText: "Ordered Feb 3, 2022",
    description: "2344896",
  },
  {
    id: 2,
    href: "#",
    productName: "Blogger",
    articleCount: "5 articles",
    wordCount: "1,000 words",
    statusText: "Ordered Feb 3, 2022",
    description: "2344896",
  },
  {
    id: 3,
    href: "#",
    productName: "Blogger",
    articleCount: "5 articles",
    wordCount: "1,000 words",
    statusText: "Ordered Feb 3, 2022",
    description: "2344896",
  },
];

export default function OrderHistoryFeed() {
  const { bgColor, getBgColor } = useSettingsContext();
  const [history, setHistory] = useState([]);
  const { reportData, displayWriterInfo, displayNotes } = useReportContext();

  useEffect(() => {
    if (reportData.orderid) {
      setHistory(reportData.orderHistory);
    }
  }, [reportData]);

  function formatDate(dateString) {
    const date = new Date(dateString);

    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);

    const day = date.getDate();
    const formattedDay = day + getOrdinalSuffix(day);

    function getOrdinalSuffix(day) {
      const suffixes = ["th", "st", "nd", "rd"];
      const remainder = day % 100;
      return suffixes[(remainder - 20) % 10] || suffixes[remainder] || "th";
    }

    return formattedDate.replace(/(\d+)/, formattedDay);
  }

  function createUrl(order) {
    return `https://www.thehoth.com/reports/view/${order.orderID}?h=${order.hash}`;
  }

  return (
    <div className="mx-auto">
      <ul className="divide-y divide-black/5 mt-2">
        {history.slice(0, 5).map((order, index) => (
          <li key={order.orderID} className="relative flex items-center space-x-4 py-3 group hover:bg-white/70 duration-200 rounded-sm -mx-3 px-3">
            <div className="min-w-0 flex-auto">
              <div className="flex items-center gap-x-3">
                <h2 className="min-w-0 text-sm font-semibold leading-6 text-black">
                  <a href={createUrl(order)} rel="noopener noreferrer" target="_blank" className="flex gap-x-1">
                    <span className="whitespace-nowrap">{reportData?.controller === "hothblogger" ? "Blogger" : "WebCopy"}</span>
                    <span className="text-gray-700">-</span>
                    <span className="truncate">{order.headline}</span>
                    <span className="absolute inset-0" />
                  </a>
                </h2>
              </div>
              <div className="mt-0.5 flex items-center gap-x-1.5 text-xs leading-5 text-gray-400">
                <p className="truncate">{order.orderID}</p>
                <span className="text-gray-400">|</span>
                <p className="whitespace-nowrap">{formatDate(order.orderCreatedOn)}</p>
              </div>
            </div>
            <svg style={{ color: getBgColor(), opacity: 1 }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 text-blue-500 group-hover:scale-[1.15] duration-200">
              <path fillRule="evenodd" d="M3 10a.75.75 0 01.75-.75h10.638L10.23 5.29a.75.75 0 111.04-1.08l5.5 5.25a.75.75 0 010 1.08l-5.5 5.25a.75.75 0 11-1.04-1.08l4.158-3.96H3.75A.75.75 0 013 10z" clipRule="evenodd" />
            </svg>
          </li>
        ))}
      </ul>
    </div>
  );
}
