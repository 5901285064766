import React, { createContext, useContext, useState, useCallback, useEffect } from "react";

const ReportContext = createContext();
export const useReportContext = () => useContext(ReportContext);

export default function ReportContextProvider(props) {
  const [reportData, setReportData] = useState({});
  const [semRushData, setSemRushData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [shareView, setShareView] = useState(false);
  const [error, setError] = useState(null);
  const [reportType, setReportType] = useState(null);

  // order-specific customization settings
  const [displayOrderSummary, setDisplayOrderSummary] = useState(true);
  const [displayOrderHistory, setDisplayOrderHistory] = useState(true);
  const [displayNotes, setDisplayNotes] = useState(true);
  const [displayWriterInfo, setDisplayWriterInfo] = useState(true);
  const [note, setNote] = useState("");

  const pullOrderSettings = useCallback(
    (orderSettings) => {
      if (orderSettings) {
        if (typeof orderSettings.displayOrderSummary !== "undefined") {
          setDisplayOrderSummary(orderSettings.displayOrderSummary);
        }
        if (typeof orderSettings.displayOrderHistory !== "undefined") {
          setDisplayOrderHistory(orderSettings.displayOrderHistory);
        }
        if (typeof orderSettings.displayNotes !== "undefined") {
          setDisplayNotes(orderSettings.displayNotes);
        }
        if (typeof orderSettings.displayWriterInfo !== "undefined") {
          setDisplayWriterInfo(orderSettings.displayWriterInfo);
        }
        if (typeof orderSettings.note !== "undefined") {
          setNote(orderSettings.note);
        }
      }
    },
    [setDisplayOrderSummary, setDisplayOrderHistory, setDisplayNotes, setDisplayWriterInfo, setNote]
  );

  const pullReportData = useCallback(async () => {
    let url = new URL(window.location.href);
    let id;

    setIsLoading(true); // set loading state to true

    switch (url.host) {
      case "view.seocampaignreport.com":
        // view.seocampaignreport.com/idvalue?h=hashvalue
        setShareView(true);
        id = url.pathname.slice(1, url.pathname.length);
        break;
      default:
        const pathnameRegex = /^\/reports\/view\/(\d+)$/i;
        if (!pathnameRegex.test(url.pathname)) {
          setError({ type: "url", message: "Invalid URL format. Please provide a valid URL." });
          return;
        }

        // www.thehoth.com/reports/view/idvalue?h=hashvalue
        // localhost:3000/reports/view/idvalue?h=hashvalue
        id = url.pathname.slice(14, url.pathname.length);
        break;
    }
    let hash = url.searchParams.get("h");
    try {
      // order report data pull
      const response = await fetch(`https://www.thehoth.com/internalapi/campaignreport/order/${id}/${hash}`, {
        headers: { "Content-Type": "application/json" },
      });
      if (!response.ok) {
        let details = await response.text();
        throw new Error(details);
      }
      const data = await response.json();
      setReportData(data);
      setReportType(data.controller);
      if (data.controller === "hothblogger") {
        // semrush data pull
        let jwtArray = [];
        for (const entry of data.entries) {
          jwtArray.push(entry.topic.jwt);
        }
        let semRushInfo = [];
        for (const jwt of jwtArray) {
          const response = await fetch("https://campaign-report-semrush-runner.nnm.workers.dev/", {
            method: "POST",
            body: JSON.stringify({ jwt }),
          });
          let newInfo = await response.json();
          semRushInfo.push(newInfo);
        }
        setSemRushData(semRushInfo);
      }
      // apply pulled order-specific customization settings
      pullOrderSettings(data.orderSettings);
    } catch (error) {
      console.log("Something went wrong with the data pull.");
      console.error(error);
      setError({ type: "api", message: error.message || "An error occurred while retrieving data." });
    } finally {
      setIsLoading(false); // set loading state to false
    }
  }, [pullOrderSettings]);

  const handleOrderSave = useCallback(
    async (orderId) => {
      let orderSettings = {};
      if (!displayOrderSummary) {
        orderSettings.displayOrderSummary = displayOrderSummary;
      }
      if (!displayOrderHistory) {
        orderSettings.displayOrderHistory = displayOrderHistory;
      }
      if (!displayNotes) {
        orderSettings.displayNotes = displayNotes;
      }
      if (!displayWriterInfo) {
        orderSettings.displayWriterInfo = displayWriterInfo;
      }

      await fetch(`https://www.thehoth.com/internalapi/campaignreport/order/${orderId}`, {
        method: "POST",
        body: JSON.stringify({settings: orderSettings}),
        credentials: "include",
        headers: {
          "content-type": "application/json",
        },
      });
    },
    [displayOrderSummary, displayOrderHistory, displayNotes, displayWriterInfo]
  );

  const handleDisplayWriterInfoChange = (value) => {
    setDisplayWriterInfo(value);
    // TODO: add toast message ?
  };

  // Update the favicon based on the shareView state
  useEffect(() => {
    const favicon = document.querySelector("link[rel='icon']");
    favicon.href = shareView ? "/favicon.png" : "/hothfavicon.png";
  }, [shareView]);

  useEffect(() => {
    pullReportData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ReportContext.Provider
      value={{
        reportData,
        setReportData,
        reportType,
        setReportType,
        semRushData,
        setSemRushData,
        shareView,
        pullReportData,
        isLoading,
        displayOrderSummary,
        setDisplayOrderSummary,
        displayOrderHistory,
        setDisplayOrderHistory,
        displayNotes,
        setDisplayNotes,
        displayWriterInfo,
        setDisplayWriterInfo: handleDisplayWriterInfoChange,
        note,
        setNote,
        handleOrderSave,
        error,
        setError,
      }}
    >
      {props.children}
    </ReportContext.Provider>
  );
}
