import CustomizeForm from "../components/CustomizeForm/CustomizeForm";
import { classNames } from "../utils/utils";
import { useSettingsContext } from "../utils/SettingsContext";
import { Tooltip } from "@mui/material";
import { useState } from "react";
import { motion } from "framer-motion";
import StyledTooltip from "../components/StyledToolTip/StyledToolTip";
import { useReportContext } from "../utils/ReportContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CopyClipboardButton from "../components/CopyClipboardButton/CopyClipboardButton";

export default function SidebarLayout(props) {
  const { settingsOpen, setSettingsOpen, agencyName } = useSettingsContext();
  const [showSidebarTooltip, setShowSidebarTooltip] = useState(true);
  const [sideBarTooltipDelay, setSideBarTooltipDelay] = useState(0.5);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [wiggleInput, setWiggleInput] = useState(false);

  const [isFormValid, setIsFormValid] = useState(false); // Allow preview & share button to be enabled only when agency name is provided (or other required fields from CustmoizeForm)

  const { handleSave } = useSettingsContext();
  const { reportData } = useReportContext();

  function handleFormValidChange(event) {
    setIsFormValid(event);
    //console.log("form valid change: ", event);
  }

  function handleShareReport(event) {
    setFormSubmitted(true); // set form submitted to true, to show errors if any in the CustomizeForm component
    wiggleInputWithDelay(); // Wiggle the input to show errors if any in the CustomizeForm component
    navigator.clipboard.writeText(`https://view.seocampaignreport.com/${reportData.orderid}?h=${reportData.hash}`);
    toast.success("Link copied to clipboard!", {
      position: toast.POSITION.BOTTOM_RIGHT,
      hideProgressBar: true,
      theme: "dark",
    });
  }

  function handlePreviewClick() {
    wiggleInputWithDelay();
    setFormSubmitted(true); // set form submitted to true, to show errors if any in the CustomizeForm component
    if (!agencyName) {
      //setIsFormValid(false);
      toast.error("Missing agency name!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        hideProgressBar: true,
        theme: "dark",
      });
    } else {
      const reportURL = `https://view.seocampaignreport.com/${reportData.orderid}?h=${reportData.hash}`;
      window.open(reportURL, "_blank");
    }
  }

  function wiggleInputWithDelay() {
    setWiggleInput(true);
    setTimeout(() => {
      setWiggleInput(false);
    }, 600); // 600ms is the duration of the wiggle animation
  }

  function handleQuestionIconClick() {
    setSideBarTooltipDelay(0);
    setShowSidebarTooltip(true);
  }

  return (
    <>
      <ToastContainer />

      {/* Top Bar: Opened while customize settings sidebar is opened */}
      <div className={classNames(settingsOpen ? "fixed translate-y-0" : "fixed -translate-y-full", "bg-gray-800 text-white duration-0 shadow h-14 border-b-2 border-red-500 top-0 w-screen z-50")}>
        <div className="relative mx-auto px-4 sm:px-6 lg:px-8 flex h-full justify-between items-center flex grow">
          {/* Back button */}
          <div className="flex items-center gap-x-4">
            <div className="relative group cursor-pointer opacity-30 hover:opacity-90 duration-300" onClick={() => setSettingsOpen(!settingsOpen)}>
              <StyledTooltip title="Go back">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8">
                  <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-4.28 9.22a.75.75 0 000 1.06l3 3a.75.75 0 101.06-1.06l-1.72-1.72h5.69a.75.75 0 000-1.5h-5.69l1.72-1.72a.75.75 0 00-1.06-1.06l-3 3z" clipRule="evenodd" />
                </svg>
              </StyledTooltip>
            </div>
            <div>
              <div className="font-semibold text-lg">White Label Builder</div>
            </div>
          </div>

          {/* Buttons wrapper */}
          <div className="top-bar__buttons-wrapper flex items-stretch">
            {/* Help Icon */}
            {!showSidebarTooltip && (
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { type: "tween", duration: 0.3 } }}>
                <StyledTooltip title="Show help tooltip">
                  <div className="-mr-2 cursor-pointer grid place-items-center h-full opacity-20 hover:opacity-90 duration-200" onClick={handleQuestionIconClick}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                      <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm11.378-3.917c-.89-.777-2.366-.777-3.255 0a.75.75 0 01-.988-1.129c1.454-1.272 3.776-1.272 5.23 0 1.513 1.324 1.513 3.518 0 4.842a3.75 3.75 0 01-.837.552c-.676.328-1.028.774-1.028 1.152v.75a.75.75 0 01-1.5 0v-.75c0-1.279 1.06-2.107 1.875-2.502.182-.088.351-.199.503-.331.83-.727.83-1.857 0-2.584zM12 18a.75.75 0 100-1.5.75.75 0 000 1.5z" clipRule="evenodd" />
                    </svg>
                  </div>
                </StyledTooltip>
              </motion.div>
            )}

            {/* Action Buttons */}
            <div className="w-96 pl-6">
              <div className="flex gap-x-3">
                <StyledTooltip title="Preview report in new tab">
                  <button onClick={handlePreviewClick} type="button" className="flex-1 flex gap-x-2 justify-center items-center rounded-md bg-gray-700 text-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-700 hover:bg-gray-600">
                    Preview
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                    </svg>
                  </button>
                </StyledTooltip>

                <StyledTooltip title="Copy report link to clip board">
                  <span>
                    <CopyClipboardButton
                      className="flex-1 whitespace-nowrap flex gap-x-2 justify-center items-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 disabled:bg-red-600 disabled:cursor-not-allowed disabled:opacity-30"
                      onClick={(event) => {
                        handleShareReport(event);
                        handleSave(event, reportData.userid);
                        //setSettingsOpen(false);
                      }}
                      disabled={!isFormValid}
                    >
                      Share Report Link
                    </CopyClipboardButton>
                  </span>
                </StyledTooltip>
              </div>
            </div>
          </div>

          {/* Customizing tag bottom */}
          {settingsOpen && <div className="bg-red-500 text-white absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-full pt-0 font-medium tracking-wide text-xs uppercase px-4 rounded-b">White Label View</div>}
        </div>
      </div>

      <div className={classNames(!settingsOpen ? "fixed translate-y-0" : "fixed -translate-y-full", "bg-white hidden text-black duration-0 shadow h-14 border-b-1 border-gray-200 top-0 w-screen z-50")}>
        <div className="flex items-center h-full justify-end px-6">
          <div>
            <StyledTooltip title="Copy report link to clip board">
              <button
                onClick={(event) => {
                  handleSave(event, reportData.userid);
                  //setSettingsOpen(false);
                }}
                type="button"
                className="flex-1 whitespace-nowrap flex gap-x-2 justify-center items-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
              >
                Customize & Share
              </button>
            </StyledTooltip>
          </div>
        </div>
      </div>

      <div>
        {/* Main Content Area */}
        <div className={classNames(settingsOpen ? "lg:pr-96 pt-14" : "pr-0 pt-0")}>
          <main className="py-8">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">{props.children}</div>
            <div onClick={props.onClose} className={classNames(settingsOpen ? "fixed inset-0 bg-gray-900/80 z-40 lg:hidden" : "hidden")} />
          </main>
        </div>

        {/* Static sidebar for desktop */}
        <aside className={classNames(settingsOpen ? "fixed translate-x-0" : "fixed translate-x-full", "duration-500 border-gray-200/70 border-l right-0 pt-14 inset-y-0 z-40 flex w-96 flex-col")}>
          {/* Message */}
          {showSidebarTooltip && settingsOpen && (
            <motion.div className="absolute top-20 left-0 -translate-x-full" initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { type: "tween", delay: sideBarTooltipDelay } }} onAnimationComplete={() => setSideBarTooltipDelay(0.5)}>
              <div className="relative overflow-hidden">
                <div className="bg-zinc-800 relative text-white mr-6 rounded-lg">
                  <div className="content-wrapper px-7 py-5 pr-8">
                    <h3 className="font-bold text-lg mb-2">Customize White Label Report</h3>
                    <p className="text-blue-100/70 text-sm">Use the color and style options on this side panel to personalize your white label report, then click share to copy the URL to your clipboard!</p>
                    <div className="text-right">
                      <button className="bg-gray-600/60 hover:bg-gray-500/50 font-semibold duration-300 mt-3 px-4 rounded text-sm py-2" onClick={() => setShowSidebarTooltip(false)}>
                        Got it!
                      </button>
                    </div>
                  </div>
                  <div className="absolute top-1/2 right-0 rotate-45 translate-x-1/2 h-5 w-5 bg-zinc-800 rounded -translate-y-1/2 mr-1"></div>
                </div>
                <img className="absolute bottom-0 w-32 translate-y-16 -mb-4" src={(process.env.REACT_APP_VERCEL_URL ? 'https://' + process.env.REACT_APP_VERCEL_URL : '') + "/images/original_monster.png"} alt="HOTH monster" />
              </div>
            </motion.div>
          )}

          {/* Close side bar icon */}
          <div className="absolute hidden top-2 left-0 -translate-x-full">
            <div className="relative group w-12 h-12 bg-gray-500/10 grid place-items-center rounded-lg mr-2 cursor-pointer" onClick={() => setSettingsOpen(!settingsOpen)}>
              {settingsOpen ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                  <path fillRule="evenodd" d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z" clipRule="evenodd" />
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                  <path
                    fillRule="evenodd"
                    d="M11.078 2.25c-.917 0-1.699.663-1.85 1.567L9.05 4.889c-.02.12-.115.26-.297.348a7.493 7.493 0 00-.986.57c-.166.115-.334.126-.45.083L6.3 5.508a1.875 1.875 0 00-2.282.819l-.922 1.597a1.875 1.875 0 00.432 2.385l.84.692c.095.078.17.229.154.43a7.598 7.598 0 000 1.139c.015.2-.059.352-.153.43l-.841.692a1.875 1.875 0 00-.432 2.385l.922 1.597a1.875 1.875 0 002.282.818l1.019-.382c.115-.043.283-.031.45.082.312.214.641.405.985.57.182.088.277.228.297.35l.178 1.071c.151.904.933 1.567 1.85 1.567h1.844c.916 0 1.699-.663 1.85-1.567l.178-1.072c.02-.12.114-.26.297-.349.344-.165.673-.356.985-.57.167-.114.335-.125.45-.082l1.02.382a1.875 1.875 0 002.28-.819l.923-1.597a1.875 1.875 0 00-.432-2.385l-.84-.692c-.095-.078-.17-.229-.154-.43a7.614 7.614 0 000-1.139c-.016-.2.059-.352.153-.43l.84-.692c.708-.582.891-1.59.433-2.385l-.922-1.597a1.875 1.875 0 00-2.282-.818l-1.02.382c-.114.043-.282.031-.449-.083a7.49 7.49 0 00-.985-.57c-.183-.087-.277-.227-.297-.348l-.179-1.072a1.875 1.875 0 00-1.85-1.567h-1.843zM12 15.75a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5z"
                    clipRule="evenodd"
                  />
                </svg>
              )}
              <span className="absolute top-full group-hover:block hidden left-full z-10 py-1 px-2 mt-1.5 text-xs font-normal text-white bg-gray-900 rounded-sm shadow-lg pointer-events-none whitespace-nowrap transform -translate-x-full">{settingsOpen ? "Close panel" : "Open customize panel"}</span>
            </div>
          </div>

          {/* Sidebar component */}
          <div className="flex grow relative flex-col border-gray-200 bg-gray-50 pr-1 overflow-y-hidden">
            {/* Overflow area: Reset button + Customize Form */}
            <div className="overflow-y-auto ui-scroll">
              {/* Close button inside sidebar */}
              <div className="flex justify-end w-full pt-2 border-red-500">
                <div className="relative group -mr-2 w-8 h-8 bg-gray-500/0 grid place-items-center rounded-lg mr-2 cursor-pointer" onClick={() => setSettingsOpen(!settingsOpen)}>
                  <StyledTooltip title="Close">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                      <path fillRule="evenodd" d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z" clipRule="evenodd" />
                    </svg>
                  </StyledTooltip>
                </div>
              </div>

              <div className="flex hidden justify-end pt-6 px-6">
                <button onClick={() => setSettingsOpen(false)} type="button" className="rounded-md flex-1 inline-flex justify-start gap-x-2 items-center text-sm font-semibold text-gray-900">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
                    <path fillRule="evenodd" d="M9.53 2.47a.75.75 0 010 1.06L4.81 8.25H15a6.75 6.75 0 010 13.5h-3a.75.75 0 010-1.5h3a5.25 5.25 0 100-10.5H4.81l4.72 4.72a.75.75 0 11-1.06 1.06l-6-6a.75.75 0 010-1.06l6-6a.75.75 0 011.06 0z" clipRule="evenodd" />
                  </svg>
                  <span>Reset all fields to default</span>
                </button>
              </div>

              <CustomizeForm formSubmitted={formSubmitted} wiggleInput={wiggleInput} onFormValidChange={handleFormValidChange} />
            </div>
          </div>
        </aside>
      </div>
    </>
  );
}
