import { useState, useRef, useEffect } from "react";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { classNames } from "../../utils/utils";
import { useSettingsContext } from "../../utils/SettingsContext";
import StyledTooltip from "../StyledToolTip/StyledToolTip";
import { useReportContext } from "../../utils/ReportContext";

export default function NotesPanel() {
  //const [note, setNote] = useState("");
  const [originalNote, setOriginalNote] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [isPanelVisible, setIsPanelVisible] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { getSecondaryColor } = useSettingsContext();
  const { reportData, note, setNote, shareView } = useReportContext();

  const textareaRef = useRef(null);

  useEffect(() => {
    if (reportData) {
      setOriginalNote(reportData.orderNotes);
      setNote(reportData.orderNotes);
    }
  }, [reportData]);

  useEffect(() => {
    if (note != "") {
      setIsPanelVisible(true);
    }
  }, [note]);

  useEffect(() => {
    if (textareaRef.current) {
      setTextAreaHeight();
    }
  }, [textareaRef, note]);

  function setTextAreaHeight() {
    // We need to reset the height momentarily to get the correct scrollHeight for the textarea
    textareaRef.current.style.height = "0px";
    const scrollHeight = textareaRef.current.scrollHeight;

    // We then set the height directly, outside of the render loop
    // Trying to set this with state or a ref will product an incorrect value.
    textareaRef.current.style.height = scrollHeight + "px";
  }

  function handleMenuClick(event, item) {
    if (item === "edit") {
      handleEditNote();
    } else if (item === "delete") {
      setShowConfirmation(true); //handleDeleteNote();
      event.stopPropagation();
    } else {
      console.log("handle menu click, different! ", item);
    }
  }

  function handleAddNote() {
    setIsPanelVisible(true);
    setIsEditMode(true);
    setTimeout(() => {
      if (textareaRef.current) {
        textareaRef.current.focus();
      }
    }, 0);
  }

  async function handleSaveNote() {
    setIsEditMode(false);
    // POST note to orders meta under key: "whitelabel_report_notes"
    const body = {
      settings: note,
      optionName: 'whitelabel_report_notes'
    }
    await fetch(`https://www.thehoth.com/internalapi/campaignreport/order/${reportData.orderid}`, {
      method: "POST",
      body: JSON.stringify(body),
      credentials: "include",
      headers: {
        "content-type": "application/json",
      },
    });

    setOriginalNote(note);
  }

  function handleEditNote() {
    setIsEditMode(true);

    setTimeout(() => {
      if (textareaRef.current) {
        textareaRef.current.focus();

        // set the cursor at the end of the text
        const valueLength = textareaRef.current.value.length;
        textareaRef.current.setSelectionRange(valueLength, valueLength);

        // set the textarea height
        setTextAreaHeight();
      }
    }, 0);
  }

  function handleDeleteNote() {
    setShowConfirmation(true);
  }

  async function handleConfirmDelete() {
    setIsPanelVisible(false);
    setIsEditMode(false);
    setShowConfirmation(false);
    const body = {
      settings: '',
      optionName: 'whitelabel_report_notes'
    }
    await fetch(`https://www.thehoth.com/internalapi/campaignreport/order/${reportData.orderid}`, {
      method: "POST",
      body: JSON.stringify(body),
      credentials: "include",
      headers: {
        "content-type": "application/json",
      },
    });
    setNote("");
    setOriginalNote("");
  }

  function handleCancelNote() {
    setIsEditMode(false);
    if (originalNote === "") {
      handleConfirmDelete();
    } else {
      setNote(originalNote);
    }
  }

  function handleNoteChange(event) {
    setNote(event.target.value);
  }

  function renderAddNoteButton() {
    return (
      <div onClick={handleAddNote} className="bg-gray-50 hover:bg-gray-100/80 transition-colors border border-transparent hover:border-gray-200 duration-200 rounded-md mb-4 px-4 py-4 sm:px-6 shadow cursor-pointer">
        <div className="flex justify-start items-center w-full">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 -ml-0.5 mr-2 text-blue-500" style={{ color: getSecondaryColor() }}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
            </svg>
          </div>
          <div className="font-semibold text-gray-800 text-md -mb-0.5">Add a note</div>
        </div>
      </div>
    );
  }

  function renderNoteEditor() {
    return (
      <div className="bg-gray-100/80 hover:bg-gray-100/80 border border-gray-200/50 transition-colors duration-200 rounded-md shadow divide-gray-200">
        <div className="px-4 pt-5 sm:px-6">
          <div className="flex justify-between">
            <div>
              <h3 className="font-semibold block">Notes</h3>
              <p className="text-sm text-gray-400 mt-1 mb-2">Add additional information that your clients will see.</p>
            </div>

            {note !== "" && originalNote !== "" && (
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    className="flex items-center rounded-full -mr-2 hover:bg-gray-200 transition-color duration-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-0"
                  >
                    <span className="sr-only">Open options</span>
                    <EllipsisVerticalIcon className="h-5 w-5 m-1" aria-hidden="true" />
                  </Menu.Button>
                </div>

                <Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <button onClick={(e) => handleMenuClick(e, "edit")} className={classNames(active ? "bg-gray-100 text-gray-900" : "text-gray-700", "block w-full px-4 py-2 text-left text-sm")}>
                            Edit
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button onClick={(e) => handleMenuClick(e, "delete")} className={classNames(active ? "bg-gray-100 text-gray-900" : "text-gray-700", "block w-full px-4 py-2 text-left text-sm")}>
                            Delete
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            )}
          </div>
        </div>
        <div className="px-4 mt-1 pb-3 sm:px-6">
          <textarea style={{ whiteSpace: "pre-wrap" }} rows={1} ref={textareaRef} value={note} onChange={handleNoteChange} placeholder="Type your note..." className="w-full overflow-y-hidden block text-sm mb-2 rounded-md text-gray-800 bg-white border p-2 pb-2 transition-colors duration-300 resize-none focus:outline-0 focus:border-gray-300" />
          <div className="flex gap-x-3">
            <StyledTooltip title={originalNote === "" ? "Add a note" : "Save changes"}>
              <button onClick={handleSaveNote} className="text-sm relative group px-3 py-1.5 rounded-md font-semibold border border-transparent bg-blue-500 hover:bg-blue-500/90 hover:shadow-sm transition-colors text-white duration-200 disabled:bg-gray-200 disabled:text-white disabled:opacity-20 disabled:cursor-not-allowed" disabled={note === ""} style={{ backgroundColor: getSecondaryColor() }}>
                {originalNote === "" ? "Add note" : "Save"}
              </button>
            </StyledTooltip>
            <StyledTooltip title={originalNote === "" ? "Discard note" : "Discard changes"}>
              <button onClick={handleCancelNote} className="relative group text-sm px-3 py-1.5 rounded-md font-semibold border border-gray-300 bg-transparent text-blue-500 hover:bg-gray-300/20 duration-200" style={{ color: getSecondaryColor() }}>
                Cancel
              </button>
            </StyledTooltip>
          </div>
        </div>
      </div>
    );
  }

  function renderNoteViewer() {
    return (
      <div className={"bg-gray-50 border border-gray-200/20 border-gray-200 transition-colors duration-200 rounded-md shadow divide-gray-200 " + (!shareView ? "hover:bg-gray-100/80 cursor-pointer" : "")} onClick={!shareView ? handleEditNote : null}>
        <div className="px-4 pt-5 sm:px-6">
          <div className="flex justify-between">
            <h3 className="font-semibold">Notes</h3>
            {/* we always show the more option menu when there is a note */}
            {!shareView && (
              <div>
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      className="flex items-center rounded-full -mr-2 hover:bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-0"
                    >
                      <span className="sr-only">Open options</span>
                      <EllipsisVerticalIcon className="h-5 w-5 m-1" aria-hidden="true" />
                    </Menu.Button>
                  </div>

                  <Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <button onClick={(e) => handleMenuClick(e, "edit")} className={classNames(active ? "bg-gray-100 text-gray-900" : "text-gray-700", "block w-full px-4 py-2 text-left text-sm")}>
                              Edit
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button onClick={(e) => handleMenuClick(e, "delete")} className={classNames(active ? "bg-gray-100 text-gray-900" : "text-gray-700", "block w-full px-4 py-2 text-left text-sm")}>
                              Delete
                            </button>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            )}
          </div>
        </div>
        <div className="px-4 mt-1 pb-6 sm:px-6">
          <p className="text-sm text-gray-500">{note}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="relative">
      <div>{isPanelVisible ? (isEditMode ? renderNoteEditor() : renderNoteViewer()) : (!shareView ? renderAddNoteButton() : (reportData.orderNotes && renderNoteViewer()))}</div>
      {showConfirmation && (
        <div className="absolute rounded-md text-white top-0 left-0 h-full w-full right-0 bottom-0 bg-zinc-900/80 z-50 flex justify-center items-center">
          <div className="shadow-0">
            <p className="text-md mb-1 font-semibold text-center">Delete this note?</p>
            <div className="flex justify-center">
              <button className="text-sm px-3 py-1.5 rounded-md font-semibold border border-blue-500/50 bg-white text-blue-500 duration-100 mr-2" onClick={handleConfirmDelete}>
                Delete
              </button>
              <button className="text-sm px-3 py-1.5 rounded-md font-semibold border border-blue-500/50 bg-white text-blue-500 duration-100" onClick={() => setShowConfirmation(false)}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
