import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import ReportContextProvider from "./utils/ReportContext";
import SettingsContextProvider from "./utils/SettingsContext";
import RoutingContextProvider from "./utils/RoutingContext";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <RoutingContextProvider>
            <ReportContextProvider>
                <SettingsContextProvider>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </SettingsContextProvider>
            </ReportContextProvider>
        </RoutingContextProvider>
    </React.StrictMode>
);
