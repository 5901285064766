import React from "react";
import { CheckIcon, ArrowRightIcon } from "@heroicons/react/20/solid";
import { classNames } from "../../utils/utils";

const eventTypes = {
  completed: { icon: CheckIcon, bgColorClass: "bg-green-500" },
  current: { icon: ArrowRightIcon, bgColorClass: "bg-blue-500", textColorClass: "text-blue-500" },
};
const timeline = [
  {
    id: 1,
    type: eventTypes.completed,
    content: "Preparing Topics",
    date: "December 8, 2022",
    datetime: "2020-09-20",
  },
  {
    id: 2,
    type: eventTypes.completed,
    content: "Topic Approval",
    date: "December 8, 2022",
    datetime: "2020-09-22",
  },
  {
    id: 3,
    type: eventTypes.completed,
    content: "Preparing Article",
    date: "December 8, 2022",
    datetime: "2020-09-28",
  },
  {
    id: 4,
    type: eventTypes.completed,
    content: "Article Approval",
    date: "December 8, 2022",
    datetime: "2020-09-30",
  },
  {
    id: 5,
    type: eventTypes.completed,
    content: "Order Complete",
    date: "December 8, 2022",
    datetime: "2020-09-30",
  },
  {
    id: 6,
    type: eventTypes.current,
    content: "Post articles on your blog",
    copy: "Click through each article and copy the content into your blog. You can download your images there as well.",
    datetime: "2020-10-04",
    cta: "Learn how",
    ctaURL: "#",
  },
];

export default function TimelineFeedPanel() {
  return (
    <>
      {/* Timeline */}
      <section className="bg-gray-50 px-4 py-5 pb-10 shadow-0 sm:rounded-lg sm:px-6 sticky top-4 hidden">
        <h2 id="timeline-title" className="text-lg font-bold text-gray-800">
          Order Timeline
        </h2>

        {/* Timeline Feed */}
        <div className="mt-6 flow-root">
          <ul className="-mb-8">
            {timeline.map((item, itemIdx) => (
              <li key={item.id}>
                <div className="relative pb-6">
                  {itemIdx !== timeline.length - 1 ? <span className="absolute left-3 top-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" /> : null}
                  <div className="relative flex space-x-3">
                    <div>
                      <span className={classNames(item.type.bgColorClass, "h-6 w-6 rounded-full flex items-center justify-center ring-4 ring-gray-50")}>
                        <item.type.icon className={classNames(item.type.iconColorClass, "h-4 w-4 text-white")} aria-hidden="true" />
                      </span>
                    </div>
                    <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-0.5">
                      <div>
                        <p className={classNames(item.type.textColorClass, "text-sm font-semibold text-gray-800")}>{item.content}</p>
                        <time className="whitespace-nowrap text-right text-xs text-gray-400" dateTime={item.datetime}>
                          {item.date}
                        </time>
                        {item.copy && <div className="text-sm text-gray-500 mt-2">{item.copy}</div>}
                        {item.cta && (
                          <a href={item.ctaURL} className="text-sm text-blue-500 font-semibold mt-4 block">
                            {item.cta}
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="mt-8 flex flex-col justify-stretch hidden">
          <button type="button" className="inline-flex items-center justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
            Post articles on your blog
          </button>
        </div>
      </section>
    </>
  );
}
