import React, { useState } from "react";
import reactCSS from "reactcss";
import { SketchPicker } from "react-color";

function ColorPicker(props) {
  const { title, state, setState } = props;
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState(state); // redundant

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (newColor) => {
    setState(newColor.hex);
    setColor(newColor.rgb);
  };

  const styles = reactCSS({
    default: {
      color: {
        width: "20px",
        height: "20px",
        borderRadius: "2px",
        background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
      },
      swatch: {
        padding: "0px",
        background: "#fff",
        borderRadius: "1px",
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        display: "inline-block",
        cursor: "pointer",
      },
      popover: {
        position: "absolute",
        zIndex: "30",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  return (
    <div>
      <div className="flex items-center justify-between">
        {title ? (
          <label htmlFor={title} className="block text-sm font-semibold leading-6 text-gray-900">
            {title}
          </label>
        ) : null}
      </div>
      <div onClick={handleClick} className="flex pr-2 justify-start items-center border-gray-200 border rounded-sm mt-1 mb-3 duration-200 px-2 py-1.5 cursor-pointer bg-white/80 hover:bg-white hover:border-gray-300">
        <div style={styles.color} className="mr-2 border border-gray-500/10" />
        <div className="text-sm font-medium">{state}</div>
      </div>

      {displayColorPicker ? (
        <div style={styles.popover}>
          <div style={styles.cover} onClick={handleClose} />
          <SketchPicker color={color} onChange={handleChange} />
        </div>
      ) : null}
    </div>
  );
}

export default ColorPicker;
