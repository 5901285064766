import {} from "@heroicons/react/24/solid";
import React, { useEffect, useState } from "react";
import OrderHistoryFeed from "../OrderHistoryFeed/OrderHistoryFeed";
import { AnimatePresence, motion } from "framer-motion";
import { useReportContext } from "../../utils/ReportContext";

export default function WebCopySummary() {
  const { displayOrderHistory } = useReportContext();
  const [customerInputs, setCustomerInputs] = useState(null);
  const { reportData } = useReportContext();

  useEffect(() => {
    if (reportData.orderid) {
      setCustomerInputs(reportData.customerInputs);
    }
  }, [reportData]);

  const questions = [
    {
      title: "What is your current page URL",
      attributeName: "currentPageUrl",
    },
    {
      title: "What is your business name?",
      attributeName: "businessName",
    },
    {
      title: "What is the intended result?",
      attributeName: "intendedResult",
    },
    {
      title: "Target Audience",
      attributeName: "targetAudience",
    },
    {
      title: "Target Location",
      attributeName: "targetLocation",
    },
    {
      title: "Keywords",
      attributeName: "keywords",
    },
    {
      title: "Instructions for writer",
      attributeName: "instructionsForWriter",
    },
    {
      title: "Who are your competitors?",
      attributeName: "competitors",
    },
  ];
  return (
    <>
      <div className="min-h-full">
        <div className="py-4">
          <div className="mx-auto mt-4 grid max-w-3xl grid-cols-1 gap-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
            {/* Left Column */}
            <div className="space-y-12 lg:col-span-2 lg:col-start-1">
              {customerInputs && (
                <>
                  {questions.map((question) => {
                    const { title, attributeName } = question;
                    const response = customerInputs[attributeName];

                    return response ? (
                      <section key={attributeName}>
                        <h3 className="font-semibold mb-2 text-md">{title}</h3>
                        {attributeName === "currentBlogUrl" ? (
                          <a href={response} className="text-blue-400 hover:text-blue-500 duration-100 underline" target="_blank" rel="noopener noreferrer">
                            {response}
                          </a>
                        ) : (
                          <p className="text-gray-500 font-sm">{response}</p>
                        )}
                      </section>
                    ) : null;
                  })}
                </>
              )}
            </div>

            {/* Right Column */}
            <section aria-labelledby="timeline-title" className="lg:col-span-1 lg:col-start-3">
              <AnimatePresence initial={false}>
                {displayOrderHistory && (
                  <motion.div className="bg-gray-50 px-4 py-5 shadow-0 sm:rounded-lg sm:px-6 sticky top-4" initial={{ opacity: 0, scale: 0.9 }} animate={{ opacity: 1, scale: 1, transition: { type: "tween", duration: 0.2 } }} exit={{ opacity: 0, scale: 0.9, transformOrigin: "center center", transition: { duration: 0.1 } }}>
                    <h2 id="timeline-title" className="text-lg font-bold text-gray-800">
                      Order History
                    </h2>
                    <p className="text-gray-500 font-sm mt-2">A list of all orders for clientname.</p>
                    <OrderHistoryFeed historyFeed={[1, 2, 3]} />
                  </motion.div>
                )}
              </AnimatePresence>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}
