import "./App.css";
import React from "react";
import { useRoutingContext } from "./utils/RoutingContext";
import { useReportContext } from "./utils/ReportContext";

import SidebarLayout from "./layouts/SidebarLayout";
import WebCopyReport from "./reports/WebCopyReport";
import BloggerReport from "./reports/BloggerReport";
import DevLinks from "./components/DevLinks/DevLinks";
import ArticleDetails from "./components/ArticleDetails/ArticleDetails";
import { AnimatePresence } from "framer-motion";
import PreviewReport from "./reports/PreviewReport";
import PageDetails from "./components/WebCopy/PageDetails";

function App() {
  const { articleDetailsView, pageDetailsView } = useRoutingContext();
  const { reportType, error, shareView } = useReportContext();

  if (error) {
    return (
      <>
        {error.type === "url" ? (
          <div className="error-screen min-h-screen w-full grid place-items-center">
            <div className="text-center space-y-4">
              <div className="mx-auto inline-block">
                <img className="h-16" src={(process.env.REACT_APP_VERCEL_URL ? 'https://' + process.env.REACT_APP_VERCEL_URL : '') + "/images/hoth_404.png"} alt="Report not found image" />
              </div>
              <div>
                <h1 className="text-sm font-bold bg-red-50 px-3 py-1.5 mx-auto rounded-md text-red-700 sr-only inline-block">Loading error</h1>
              </div>
              <p className="text-3xl font-bold">Oops, that URL doesn't look right</p>
              <p className="text-lg text-gray-700">
                Make sure it's a valid URL and try again. If you're still having trouble,{" "}
                <a href="#" className="text-red-500 font-semibold hover:underline">
                  contact support
                </a>
                .
              </p>
              <small className="mt-4 block text-center text-gray-400">Example url: https://www.thehoth.com/reports/view/1234567?h=1234657890</small>
            </div>
          </div>
        ) : (
          <div className="error-screen min-h-screen w-full grid place-items-center">
            <div className="text-center space-y-4">
              <div className="mx-auto inline-block">
                <img className="h-16" src={(process.env.REACT_APP_VERCEL_URL ? 'https://' + process.env.REACT_APP_VERCEL_URL : '') + "/images/hoth_404.png"} alt="Report not found image" />
              </div>
              <div>
                <h1 className="text-sm font-bold bg-red-50 px-3 py-1.5 mx-auto rounded-md text-red-700 sr-only inline-block">Loading error</h1>
              </div>
              <p className="text-3xl font-bold">Failed to load order report</p>
              <p className="text-lg text-gray-700">
                Please try again later or{" "}
                <a href="#" className="text-red-500 font-semibold hover:underline">
                  contact support
                </a>
                .
              </p>
              <small className="mt-4 block text-center text-gray-400">Error: {error.message}</small>
            </div>
          </div>
        )}
      </>
    );
  }

  return (
    <>
      {/* Quick navigation links for Web Copy vs Blogger reports while developing */}
      {/*<DevLinks />*/}

      <AnimatePresence mode="wait">
        {reportType === "webcopy" && !pageDetailsView && (
          <SidebarLayout>
            <WebCopyReport />
          </SidebarLayout>
        )}
        {pageDetailsView && (
          <SidebarLayout>
            <PageDetails />
          </SidebarLayout>
        )}
        {reportType === "hothblogger" && !articleDetailsView && (
          <SidebarLayout>
            {!shareView && <BloggerReport />}
            {shareView && <PreviewReport />}
          </SidebarLayout>
        )}
        {articleDetailsView && (
          <SidebarLayout>
            <ArticleDetails />
          </SidebarLayout>
        )}
      </AnimatePresence>
    </>
  );
}

export default App;
