import React, { Fragment, useRef, useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { ArrowDownOnSquareIcon } from "@heroicons/react/24/outline";
import { BookmarkSquareIcon, CalendarDaysIcon, LifebuoyIcon } from "@heroicons/react/24/outline";
import { CSVDownload } from 'react-csv';

const resources = [
  { name: "Candice Wu", description: "Topic Author", href: "#", icon: LifebuoyIcon },
  { name: "Lana Steiner", description: "Article Author", href: "#", icon: BookmarkSquareIcon },
  { name: "Drew Cano", description: "Quality Control", href: "#", icon: CalendarDaysIcon },
];

export default function HoverModal({ team, children }) {
  const [teamDownload, setTeamDownload] = useState([]);
  const [teamDownloadNeeded, setTeamDownloadNeeded] = useState(false);
  useEffect(() => {
    if (team.length > 0) {
      const CSVData = [['Name', 'Image', 'Bio', 'Role']];
      for (const member of team) {
        const info = [];
        info.push(member.name);
        info.push(member.image);
        info.push(member.bio);
        switch (member.role) {
          case "topic_created_by":
            info.push("Topic Author");
            break;
          case "article_written_by":
            info.push("Article Author");
            break;
          case "article_quality_checked_by":
            info.push("Quality Control");
            break;
          default:
            break;
        }
        CSVData.push(info);
      }
      setTeamDownload(CSVData);
    }
  }, []);

  const buttonRef = useRef(); // To trigger the button click when trying to close the popover

  const handleCloseModal = (event) => {
    setTeamDownloadNeeded(false);
    console.log("handleCloseModal");
    event.stopPropagation();
    event.preventDefault();
    buttonRef.current?.click();
  };

  function roleIdToString(roleId) {
    switch (roleId) {
      case "topic_created_by":
        return "Topic Author";
      case "article_written_by":
        return "Article Author";
      case "article_quality_checked_by":
        return "Quality Control";
      default:
        return "";
    }
  }

  return (
    <Popover className="relative">
      <Popover.Button ref={buttonRef} className="ring-0 outline-0 flex flex-col justify-center items-center">
        <div>{children}</div>
      </Popover.Button>

      <Transition as={Fragment} enter="transition ease-out duration-200" enterFrom="opacity-0 translate-y-1" enterTo="opacity-100 translate-y-0" leave="transition ease-in duration-150" leaveFrom="opacity-100 translate-y-0" leaveTo="opacity-0 translate-y-1">
        <Popover.Panel className="PopoverPanel absolute left-1/2 z-10 mt-3 flex w-screen max-w-max -translate-x-1/2 px-4">
          <div className="w-screen max-w-xs flex-auto overflow-hidden rounded-md bg-zinc-900 text-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
            <div className="pb-2">
              <div className="border-b border-gray-700 px-3 py-4 sm:px-4 mb-2">
                <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
                  <div className="ml-5 mt-2 flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 text-gray-500 mr-2">
                      <path d="M4.5 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM14.25 8.625a3.375 3.375 0 116.75 0 3.375 3.375 0 01-6.75 0zM1.5 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM17.25 19.128l-.001.144a2.25 2.25 0 01-.233.96 10.088 10.088 0 005.06-1.01.75.75 0 00.42-.643 4.875 4.875 0 00-6.957-4.611 8.586 8.586 0 011.71 5.157v.003z" />
                    </svg>
                    <h3 className="text-base font-semibold leading-6">Team</h3>
                  </div>
                  <div className="flex items-center">
                    {teamDownload ?
                        <ArrowDownOnSquareIcon className="w-6 h-6 text-gray-400" onClick={() => setTeamDownloadNeeded(true)}/>
                    : null}
                    {teamDownloadNeeded ?
                        <CSVDownload
                            data={teamDownload}
                            filename={'writing-team-download.csv'}
                            title="Download Team as CSV"
                            target="_blank"
                        />
                      : null}

                    <button onClick={handleCloseModal} className="close-icon ml-4 mt-2 flex-shrink-0">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 text-gray-400 pointer-events-none">
                        <path fillRule="evenodd" d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z" clipRule="evenodd" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              {team.map((member) => (
                <div key={member.name} className="group relative flex gap-x-3 px-5 py-2">
                  <img className="inline-block h-10 w-10 rounded-md" src={member.image} alt="" />
                  <div>
                    <p className="mb-0.5 text-gray-400 text-xs">{roleIdToString(member.role)}</p>
                    <div className="font-semibold text-white">
                      {member.name}
                      <span className="absolute inset-0" />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
