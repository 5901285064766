export function getTextColor(brandColor) {
  // Get the red, green, and blue values of the brand color
  const r = parseInt(brandColor.slice(1, 3), 16);
  const g = parseInt(brandColor.slice(3, 5), 16);
  const b = parseInt(brandColor.slice(5, 7), 16);

  // Calculate the relative luminance of the brand color
  const L = (0.2126 * r) / 255 + (0.7152 * g) / 255 + (0.0722 * b) / 255;

  // Determine whether to use a light or dark text color
  return L > 0.5 ? "#000000" : "#ffffff";
}

export function getOppositeColorFromText(color) {
  const textColor = getTextColor(color);
  return textColor === "#000000" ? "#ffffff" : "#000000";
}

export function adjustColorBrightness(color, factor) {
  // Get the red, green, and blue values of the color
  const r = parseInt(color.slice(1, 3), 16);
  const g = parseInt(color.slice(3, 5), 16);
  const b = parseInt(color.slice(5, 7), 16);

  // Adjust the brightness of the color by multiplying the values by the factor
  const newR = Math.round(Math.min(r * factor, 255));
  const newG = Math.round(Math.min(g * factor, 255));
  const newB = Math.round(Math.min(b * factor, 255));

  // Return the adjusted color as a string in hexadecimal format
  return `#${newR.toString(16).padStart(2, "0")}${newG.toString(16).padStart(2, "0")}${newB.toString(16).padStart(2, "0")}`;
}

export function getAdjustedBrandColor(brandColor) {
  const r = parseInt(brandColor.slice(1, 3), 16);
  const g = parseInt(brandColor.slice(3, 5), 16);
  const b = parseInt(brandColor.slice(5, 7), 16);

  const maxRGB = Math.max(r, g, b);
  const minRGB = Math.min(r, g, b);
  const chroma = maxRGB - minRGB;

  let hue = 0;
  let saturation = 0;

  if (chroma === 0) {
    hue = 0;
    saturation = 0;
  } else {
    switch (maxRGB) {
      case r:
        hue = ((g - b) / chroma) % 6;
        break;
      case g:
        hue = (b - r) / chroma + 2;
        break;
      case b:
        hue = (r - g) / chroma + 4;
        break;
    }
    hue = Math.round(hue * 60);
    saturation = chroma / maxRGB;
  }

  let lightness = (0.2126 * r) / 255 + (0.7152 * g) / 255 + (0.0722 * b) / 255;

  if (lightness > 0.8) {
    lightness -= 0.2;
  }

  // Only return the darkened color if the original color is too bright
  return lightness > 0.5 ? `hsl(${hue}, ${saturation * 100}%, ${lightness * 100}%)` : brandColor;
}

// TODO: Check if still needed, if not, delete
export function getContrastingColor(hexColor) {
  // Convert the hex color value to RGB format
  const red = parseInt(hexColor.substring(1, 3), 16);
  const green = parseInt(hexColor.substring(3, 5), 16);
  const blue = parseInt(hexColor.substring(5, 7), 16);

  // Calculate the relative luminance of the color
  const luminance = (0.2126 * red + 0.7152 * green + 0.0722 * blue) / 255;

  // Determine whether to use black or white text based on the luminance value
  return luminance > 0.5 ? "#000000" : hexColor;
}
