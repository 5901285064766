import React, { useState, useEffect, useRef } from "react";

import { AnimatePresence, motion } from "framer-motion";

import HoverModal from "../HoverModal/HoverModal";
import { useRoutingContext } from "../../utils/RoutingContext";
import { useReportContext } from "../../utils/ReportContext";
import { useSettingsContext } from "../../utils/SettingsContext";

export default function ArticleListItem({ entry, index }) {
  const articleRef = useRef(null);
  const { getSecondaryColor } = useSettingsContext();
  const { setArticleDetailsView, setArticleId, dimensions, setDimensions, setArtcileIndex } = useRoutingContext();
  const { reportData, displayWriterInfo, semRushData } = useReportContext();
  const [team, setTeam] = useState([]);
  const [semRushStats, setSemRushStats] = useState({});
  const [keywordsAreOneForOne, setKeywordsAreOneForOne] = useState(true);

  useEffect(() => {
    if (reportData.orderid) {
      setTeam(entry.team.filter((member) => member.name !== "EditAI W."));
    }
  }, [entry, reportData]);

  useEffect(() => {
    if (semRushData) {
      let match;
      if (semRushData[index].length > 1) {
        setKeywordsAreOneForOne(false);
        match = {...semRushData[index][0]};
      } else {
        match = semRushData.find((obj) => obj.keyword === entry.topic.keywords);
      }
      setSemRushStats(match);
    }
  }, [semRushData, entry.topic.keywords]);

  /*
  // const [dimensions, setDimensions] = useState({ top: 0, left: 0, width: 0, height: 0 });
  // Old useEffect to set dimensions in link state variable
  useEffect(() => {
    if (articleRef.current) {
      const { top, left } = articleRef.current.getBoundingClientRect();
      setDimensions({
        top: top,
        left: left,
        width: articleRef.current.offsetWidth,
        height: articleRef.current.offsetHeight,
      });
    }
  }, []);*/

  const changeViewToArticleDetails = () => {
    if (articleRef.current) {
      const { top, left } = articleRef.current.getBoundingClientRect();
      setDimensions({
        top: top,
        left: left,
        width: articleRef.current.offsetWidth,
        height: articleRef.current.offsetHeight,
      });
    }
    setArtcileIndex(index);
    setArticleDetailsView(true);
    setArticleId(entry.id);
  };

  const handleSetDimensions = () => {
    if (articleRef.current) {
      const { top, left } = articleRef.current.getBoundingClientRect();
      setDimensions({
        top: top,
        left: left,
        width: articleRef.current.offsetWidth,
        height: articleRef.current.offsetHeight,
      });
    }
  };

  return (
    // <Link to={`/2334754/dc782f372f/${props.entry.id}`} state={{ dimensions: dimensions, count: props.count }}>
    <div onMouseEnter={handleSetDimensions} onClick={changeViewToArticleDetails}>
      {/* onClick={() => handleArticleClick(props.entry.id)} */}
      <article ref={articleRef} className="bg-gray-50 border border-gray-100 border-1 shadow sm:rounded-md hover:cursor-pointer hover:bg-gray-100/90 hover:border-gray-200 hover:shadow-md duration-200">
        <header className="px-4 py-5 sm:px-6">
          {/* Temp display of dimension for dev & testing purposes -TODO: Remove */}
          <div className="hidden bg-amber-100 text-bold text-xs text-red-400 font-semibold inline-block py-1 px-2 border border-amber-200 rounded-full mb-2">
            Left {dimensions.left} - Top {dimensions.top} - Width: {dimensions.width} - Height {dimensions.height}
          </div>
          <div className="flex justify-between items-start">
            <div>
              <p style={{ color: getSecondaryColor(), opacity: 0.7 }} className="mb-2 text-blue-500 font-semibold text-xs">
                Article {index + 1}
              </p>
              <h2 id="applicant-information-title" className="text-lg font-semibold leading-6 text-gray-900">
                {entry.article.title}
              </h2>
            </div>
            {team.length > 0 && (
              <AnimatePresence initial={false}>
                {displayWriterInfo && (
                  <motion.div className="writers-overview flex items-center justify-end gap-x-3" initial={{ opacity: 0, scale: 0.7 }} animate={{ opacity: 1, scale: 1, transition: { type: "spring", stiffness: 150, duration: 0.1 } }} exit={{ opacity: 0, scale: 0.75, transformOrigin: "center center" }}>
                    {/* Avatars */}
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 text-zinc-700">
                      <path d="M4.5 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM14.25 8.625a3.375 3.375 0 116.75 0 3.375 3.375 0 01-6.75 0zM1.5 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM17.25 19.128l-.001.144a2.25 2.25 0 01-.233.96 10.088 10.088 0 005.06-1.01.75.75 0 00.42-.643 4.875 4.875 0 00-6.957-4.611 8.586 8.586 0 011.71 5.157v.003z" />
                    </svg>

                    {/* Avatars */}
                    <div>
                      <ClickMuncher>
                        <HoverModal team={team}>
                          <div className="isolate flex -space-x-1 overflow-hidden">
                            {team.map((member, index) => (
                              <img key={index} className="relative z-30 inline-block h-6 w-6 rounded-full ring-2 ring-white" src={member.image} alt={member.name} />
                            ))}
                          </div>
                        </HoverModal>
                      </ClickMuncher>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            )}
          </div>
        </header>
        <div className="px-4 pb-5 sm:px-6">
          <p className="mt-1 text-sm text-gray-500">{entry.article.seo_description}</p>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="flex gap-x-6 gap-y-8">
            <div className="grow">
              <dt className="text-xs font-medium text-gray-400">Targeted Keyword</dt>
              <dd className="mt-2 text-sm text-gray-900 flex items-center gap-x-2">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
                  <path fillRule="evenodd" d="M15.75 1.5a6.75 6.75 0 00-6.651 7.906c.067.39-.032.717-.221.906l-6.5 6.499a3 3 0 00-.878 2.121v2.818c0 .414.336.75.75.75H6a.75.75 0 00.75-.75v-1.5h1.5A.75.75 0 009 19.5V18h1.5a.75.75 0 00.53-.22l2.658-2.658c.19-.189.517-.288.906-.22A6.75 6.75 0 1015.75 1.5zm0 3a.75.75 0 000 1.5A2.25 2.25 0 0118 8.25a.75.75 0 001.5 0 3.75 3.75 0 00-3.75-3.75z" clipRule="evenodd" />
                </svg>
                <span className="flex items-center">{entry.topic.keywords}</span>
              </dd>
            </div>
            {semRushStats && semRushStats.search_volume &&
                <div className="text-right">
                <dt className="text-xs font-medium text-gray-400">Search&nbsp;Vol</dt>
                <dd className="mt-2 text-sm text-gray-900 font-semibold">{semRushStats.search_volume}</dd>
              </div>
            }
            {semRushStats && semRushStats.keyword_difficulty_index &&
                <div className="text-right">
                  <dt className="text-xs font-medium text-gray-400">Keyword&nbsp;Diff</dt>
                  <dd className="mt-2 text-sm text-gray-900 font-semibold">{semRushStats.keyword_difficulty_index}</dd>
                </div>
            }
          </dl>
        </div>
      </article>
    </div>
  );
}

const ClickMuncher = ({ children }) => {
  function handleStop(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  return (
    <div onMouseDown={(e) => handleStop(e)} onClick={(e) => handleStop(e)}>
      {children}
    </div>
  );
};
