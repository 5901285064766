import React, { useState, useRef, useLayoutEffect, useEffect, useMemo } from "react";

import { useSettingsContext } from "../../utils/SettingsContext";
import { Link, useLocation } from "react-router-dom";

import { motion, useAnimation } from "framer-motion";
import { useReportContext } from "../../utils/ReportContext";
import { LightBulbIcon } from "@heroicons/react/20/solid";

export default function PageDetailsOptimization(props) {
  const location = useLocation();
  const { isLoading } = useReportContext();

  const { settingsOpen, setSettingsOpen, getSecondaryColor, agencyName, imageServeUrl, displayWebsite, agencyWebsite, displayWriterInfo, displayNotes } = useSettingsContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="pt-8 space-y-12 pl-1">
      {/* Keywords section */}
      <section className="rounded-md text-gray-600 text-md pb-6 borber-b border-gray-500/0 max-w-3xl mr-auto bg-white/0">
        <div className="relative mb-3">
          <div className="absolute inset-0 flex items-center" aria-hidden="true">
            <div className="w-full border-t border-gray-100" />
          </div>
          <div className="relative flex justify-start">
            <h3 className="bg-gray-50 pr-2 font-semibold text-gray-800 flex items-center">
              {/* Key icon */}
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 mr-2  opacity-60">
                <path fillRule="evenodd" d="M15.75 1.5a6.75 6.75 0 00-6.651 7.906c.067.39-.032.717-.221.906l-6.5 6.499a3 3 0 00-.878 2.121v2.818c0 .414.336.75.75.75H6a.75.75 0 00.75-.75v-1.5h1.5A.75.75 0 009 19.5V18h1.5a.75.75 0 00.53-.22l2.658-2.658c.19-.189.517-.288.906-.22A6.75 6.75 0 1015.75 1.5zm0 3a.75.75 0 000 1.5A2.25 2.25 0 0118 8.25a.75.75 0 001.5 0 3.75 3.75 0 00-3.75-3.75z" clipRule="evenodd" />
              </svg>
              Keywords
            </h3>
          </div>
        </div>

        <p className="text-sm mb-6 max-w-2xl text-gray-500">Your target keyword is chosen using a method that combines all ranking factors for best outcome. </p>
        <ul className="space-y-6">
          <li>
            <RecommendationContainer current title="Current Ranking Target Keywords">
              digital marketing services
            </RecommendationContainer>
          </li>
          <li>
            <RecommendationContainer title="Recommended Target Keywords">classroom management</RecommendationContainer>
          </li>
          <li>
            <RecommendationContainer title="Recommended Related Keywords">classroom management strategies, classroom management techniques, effective classroom management strategies for new teachers, classroom management course, classroom management training</RecommendationContainer>
          </li>
        </ul>
      </section>

      {/* Meta description */}
      <section className="rounded-md text-gray-600 text-md overflow-hidden pb-6 borber-b border-gray-500/0 max-w-3xl mr-auto bg-white/0">
        <div className="relative mb-3">
          <div className="absolute inset-0 flex items-center" aria-hidden="true">
            <div className="w-full border-t border-gray-100" />
          </div>
          <div className="relative flex justify-start">
            <h3 className="bg-gray-50 pr-2 font-semibold text-gray-800 flex items-center">
              {/* Code Icon */}
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5 mr-2 opacity-60">
                <path fillRule="evenodd" d="M3 6a3 3 0 013-3h12a3 3 0 013 3v12a3 3 0 01-3 3H6a3 3 0 01-3-3V6zm14.25 6a.75.75 0 01-.22.53l-2.25 2.25a.75.75 0 11-1.06-1.06L15.44 12l-1.72-1.72a.75.75 0 111.06-1.06l2.25 2.25c.141.14.22.331.22.53zm-10.28-.53a.75.75 0 000 1.06l2.25 2.25a.75.75 0 101.06-1.06L8.56 12l1.72-1.72a.75.75 0 10-1.06-1.06l-2.25 2.25z" clipRule="evenodd" />
              </svg>
              Meta Descriptions
            </h3>
          </div>
        </div>
        <p className="text-sm mb-6 max-w-2xl text-gray-500">
          The meta description will show up on the search engine result pages. You will update this using the <span className="font-mono text-sm bg-gray-100 p-1 rounded-md !important">{`<meta>`}</span> tag or in the meta description field in your content management system. We recommend using Yoast for the title and description.
        </p>
        <div className="flex flex-col gap-6">
          <RecommendationContainer current title="Current Meta">
            digital marketing services
          </RecommendationContainer>
          <RecommendationContainer title="Recommended Meta">digital marketing services</RecommendationContainer>
        </div>
      </section>

      {/* H1 */}
      <section className="rounded-md text-gray-600 text-md overflow-hidden pb-6 borber-b border-gray-500/0 max-w-3xl mr-auto bg-white/0">
        <div className="relative mb-3">
          <div className="absolute inset-0 flex items-center" aria-hidden="true">
            <div className="w-full border-t border-gray-100" />
          </div>
          <div className="relative flex justify-start">
            <h3 className="bg-gray-50 pr-2 font-semibold text-gray-800">H1's</h3>
          </div>
        </div>
        <p className="text-sm mb-6 max-w-2xl text-gray-600">
          Page title appears on the top of the web page. You can update this using the <span className="font-mono text-sm bg-gray-100 p-1 rounded-md !important">{`<title>`}</span>
          &nbsp;tag or in the page title field in your content management system.
        </p>
        <div className="flex flex-col gap-6">
          <RecommendationContainer current title="Current Title">
            classroom management
          </RecommendationContainer>
          <RecommendationContainer title="Recommended Title">classroom management</RecommendationContainer>
        </div>
      </section>

      {/* Image section */}
      <section className="rounded-md text-gray-600 text-md overflow-hidden pb-6 borber-b border-gray-500/0 max-w-3xl mr-auto bg-white/0">
        <div className="relative mb-3">
          <div className="absolute inset-0 flex items-center" aria-hidden="true">
            <div className="w-full border-t border-gray-100" />
          </div>
          <div className="relative flex justify-start">
            <h3 className="bg-gray-50 pr-2 font-semibold text-gray-800 flex items-center">
              {/* Photo Icon */}
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 mr-2 opacity-60">
                <path fillRule="evenodd" d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z" clipRule="evenodd" />
              </svg>
              Alt Image Text/File Name
            </h3>
          </div>
        </div>

        <p className="text-sm mb-6 max-w-2xl text-gray-500">On each page, for any specific image on that page, we may suggest alt image text or file names. These will be descriptive of the main image on the web page. When applicable, the keyword will be included in the alt image text -- but only when appropriate.</p>

        <RecommendationContainer title="Recommended Alt Image Text/File Name">classroom management</RecommendationContainer>
      </section>
    </div>
  );
}

export function RecommendationContainer({ title, children, current }) {
  return (
    <>
      {/* Gray box for current style | Green box for recommendation style */}
      {current ? (
        <div className="flex-1">
          <div className="recommendation-container px-5 py-5 bg-gray-100/70 border-gray-300 border-l-2">
            <h4 className="font-semibold mb-3 text-gray-800 text-sm">{title}</h4>
            <p className="text-sm">{children}</p>
          </div>
        </div>
      ) : (
        <div className="recommendation-container px-5 py-5 bg-green-50 border-green-500/50 border-l-2">
          <div className="recommendation-content-wrapper flex gap-x-3">
            <div className="icon-holder">
              <LightBulbIcon className="h-4 w-4 text-green-500 mt-0.5" />
            </div>
            <div className="content-holder">
              {title ? <h4 className="font-semibold mb-3 text-sm text-gray-800">{title}</h4> : <h4>Recommendation:</h4>}
              <p className="text-sm">{children}</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
