import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";

import { useReportContext } from "../../utils/ReportContext";
import { useSettingsContext } from "../../utils/SettingsContext";
import NotesPanel from "../NotesPanel/NotesPanel";

import PagesLoadingSkeleton from "./PagesLoadingSkeleton";
import PageListItem from "./PageListItem";

export default function Pages({ items, onArticleClick }) {
  //const [isLoading, setIsLoading] = useState(true); // TODO : move to context
  const { getSecondaryColor, displayNotes } = useSettingsContext();
  const { isLoading } = useReportContext();

  function handleArticleClick(articleId) {
    onArticleClick(articleId);
  }

  return (
    <>
      <div key="pagesKey" className="min-h-full">
        <div className="py-4">
          <div className="mx-auto mt-4 grid max-w-3xl grid-cols-1 gap-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
            {/* Left Column */}
            <div className="space-y-6 lg:col-span-2 lg:col-start-1">
              <div className="">
                {/* Pages Loading State */}
                {isLoading && <PagesLoadingSkeleton />}

                {/* Articles List */}
                {!isLoading && (
                  <AnimatePresence>
                    <ul className="space-y-4 mb-16">
                      {items.map((item, index) => (
                        <motion.li key={item.id} initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0, delay: index * 0, ease: "easeOut" }}>
                          <PageListItem onArtcileSelect={handleArticleClick} item={item} index={index}></PageListItem>
                        </motion.li>
                      ))}
                    </ul>
                  </AnimatePresence>
                )}

                {items.length > 10 && (
                  <nav className="flex mt-6 items-center justify-between border-t border-gray-200 bg-white py-3" aria-label="Pagination">
                    <div className="hidden sm:block">
                      <p className="text-sm text-gray-700">
                        Page <span className="font-medium">1</span> of <span className="font-medium">2</span>
                      </p>
                    </div>
                    <div className="flex flex-1 justify-between sm:justify-end">
                      <a href="#" className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-200 hover:bg-gray-50 focus-visible:outline-offset-0">
                        Previous
                      </a>
                      <a href="#" className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-200 hover:bg-gray-50 focus-visible:outline-offset-0">
                        Next
                      </a>
                    </div>
                  </nav>
                )}
              </div>
            </div>

            {/* Right Column */}
            <aside aria-labelledby="page-list-sidebar" className="lg:col-span-1 lg:col-start-3 space-y-6">
              {/* Action panel */}
              <section className="bg-gray-50 shadow-0 sm:rounded-lg">
                <div className="px-4 py-5 sm:p-6">
                  <h3 className="text-base font-semibold leading-6 text-gray-900 flex items-center">
                    <span className="mr-1">Post pages on your blog</span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                      <path fillRule="evenodd" d="M3 10a.75.75 0 01.75-.75h10.638L10.23 5.29a.75.75 0 111.04-1.08l5.5 5.25a.75.75 0 010 1.08l-5.5 5.25a.75.75 0 11-1.04-1.08l4.158-3.96H3.75A.75.75 0 013 10z" clipRule="evenodd" />
                    </svg>
                  </h3>
                  <div className="mt-2 max-w-xl text-sm text-gray-500">
                    <p>Click through each page and copy the content into your website. You can download your images there as well.</p>
                  </div>
                </div>
              </section>

              {/* Sidebar message */}
              <section className="bg-gray-50 px-4 py-5 pb-6 shadow-0 sm:rounded-lg sm:px-6 sticky top-4">
                {/* Message */}
                <div className="flow-root">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 text-blue-500" style={{ color: getSecondaryColor() }}>
                    <path fillRule="evenodd" d="M12.516 2.17a.75.75 0 00-1.032 0 11.209 11.209 0 01-7.877 3.08.75.75 0 00-.722.515A12.74 12.74 0 002.25 9.75c0 5.942 4.064 10.933 9.563 12.348a.749.749 0 00.374 0c5.499-1.415 9.563-6.406 9.563-12.348 0-1.39-.223-2.73-.635-3.985a.75.75 0 00-.722-.516l-.143.001c-2.996 0-5.717-1.17-7.734-3.08zm3.094 8.016a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clipRule="evenodd" />
                  </svg>
                  <p className="mt-3 text-sm text-gray-500">All content has been evaluated by Sapling and Copyscape to make sure you are receiving quality, authentic content.</p>
                </div>
              </section>

              {/* Notes Panel: TODO replace with actual note */}
              <AnimatePresence initial={false}>
                {displayNotes && (
                  <motion.div key="notes-div" initial={{ opacity: 0, scale: 0.8 }} animate={{ opacity: 1, scale: 1, transition: { type: "spring", damping: 12 } }} exit={{ opacity: 0, scale: 0.92, transformOrigin: "center center" }}>
                    <NotesPanel />
                  </motion.div>
                )}
              </AnimatePresence>
            </aside>
          </div>
        </div>
      </div>
    </>
  );
}
