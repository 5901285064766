import React, { useState, useRef, useLayoutEffect, useEffect, useMemo } from "react";

import { useSettingsContext } from "../../utils/SettingsContext";
import { useLocation } from "react-router-dom";
import { motion, useAnimation } from "framer-motion";
import { useReportContext } from "../../utils/ReportContext";

import CopyClipboardIconButton from "../CopyClipboardButton/CopyClipboardIconButton";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";

const TRANSITION_DURATION_SLOW = 0.45; // TODO Move to utils aninations
const TRANSITION_DURATION_FAST = 0.2;

export default function PageDetailsContent({ item }) {
  const location = useLocation();
  const { isLoading } = useReportContext();
  const { settingsOpen, setSettingsOpen, getSecondaryColor, agencyName, imageServeUrl, displayWebsite, agencyWebsite, displayWriterInfo, displayNotes } = useSettingsContext();

  return (
    <div className="pt-8 space-y-12">
      {/* Main Description section */}
      {/* Main Description section */}
      <section className="mx-auto gap-6 max-w-7xl">
        <SectionTitle style={{ color: getSecondaryColor(), opacity: 1 }}>Meta Description</SectionTitle>
        <div className="mt-4 grid max-w-4xl grid-cols-1 gap-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="space-y-6 lg:col-span-2 lg:col-start-1 text-gray-600">{item && item.description}</div>
          <div aria-labelledby="main-description-info-panel" className="lg:col-span-1 lg:col-start-3">
            <InfoPanel>You can add this in the SEO snippet box on your blog.</InfoPanel>
          </div>
        </div>
      </section>

      {/* Formatted Page Content Section */}

      {/* Formatted Article Content Section */}
      <section className="mx-auto gap-6 max-w-7xl">
        <SectionTitle style={{ color: getSecondaryColor(), opacity: 1 }}>Formatted Article Content</SectionTitle>
        <div className="mt-4 grid max-w-4xl grid-cols-1 gap-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="space-y-6 lg:col-span-2 lg:col-start-1">
            {/* Formatted aricle body */}
            <div className="formatted-article-body rounded-md text-gray-600 text-md overflow-hidden bg-gray-100 px-6 py-6">{item && <div dangerouslySetInnerHTML={{ __html: item?.body }}></div>}</div>
          </div>
          <div aria-labelledby="formatted-article-content-info-panel" className="lg:col-span-1 lg:col-start-3">
            <InfoPanel>
              <p className="text-sm text-gray-500 mb-3">Copy the page content using the button below. You can paste is directly to your website editor.</p>
              <CopyClipboardIconButton label="Copy page to clipboard" />

              <button style={{ color: getSecondaryColor() }} type="button" className="inline-flex mt-3 py-2 -ml-2 px-2 hover:bg-gray-200/60 items-center gap-x-1.5 rounded-md text-sm font-semibold text-blue-500 duration-200 group hover:text-blue-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                <QuestionMarkCircleIcon className="h-5 w-5" />
                How to paste in your blog
              </button>
              <p className="text-xs mt-4 text-gray-500 font-medium">Content has been evaluated by Sapling and Copyscape to make sure you are receiving quality, authentic content.</p>
            </InfoPanel>
          </div>
        </div>
      </section>
    </div>
  );
}

const SectionTitle = ({ children, style }) => {
  return (
    <div className="relative">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-100" />
      </div>
      <div className="relative flex justify-start">
        <span style={style} className="bgray-50 pr-2 font-semibold text-sm text-blue-500">
          {children}
        </span>
      </div>
    </div>
  );
};

const InfoPanel = ({ children }) => {
  return (
    <div className="overflow-hidden rounded-md bg-gray-100">
      <div className="flex items-start gap-x-3 px-4 py-5 sm:p-6">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-gray-400">
            <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
          </svg>
        </div>
        <div className="text-sm text-gray-500">{children}</div>
      </div>
    </div>
  );
};
