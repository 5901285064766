import {} from "@heroicons/react/24/solid";
import HoverModal from "../HoverModal/HoverModal";

import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useSettingsContext } from "../../utils/SettingsContext";
import { AnimatePresence, motion } from "framer-motion";
import { LinkIcon } from "@heroicons/react/24/outline";
import { useRoutingContext } from "../../utils/RoutingContext";
import { useReportContext } from "../../utils/ReportContext";

export default function PageListItem({ item, index }) {
  const articleRef = useRef(null);
  const { getSecondaryColor } = useSettingsContext();
  const { setPageDetailsView, setArticleId, dimensions, setDimensions, setArtcileIndex } = useRoutingContext();
  const { reportData, displayWriterInfo } = useReportContext();
  const [team, setTeam] = useState([]);

  useEffect(() => {
    if (reportData.orderid) {
      setTeam(item?.team || []);
    }
  }, [item, reportData]);
  /*
  useEffect(() => {
    if (articleRef.current) {
      const { top, right, bottom, left } = articleRef.current.getBoundingClientRect();
      setDimensions({
        top: top,
        left: left,
        width: articleRef.current.offsetWidth,
        height: articleRef.current.offsetHeight,
      });
    }
  }, []);*/

  const changeViewToPageDetails = () => {
    if (articleRef.current) {
      const { top, left } = articleRef.current.getBoundingClientRect();
      setDimensions({
        top: top,
        left: left,
        width: articleRef.current.offsetWidth,
        height: articleRef.current.offsetHeight,
      });
    }
    setArtcileIndex(index);
    setPageDetailsView(true);
    setArticleId(item.id);
  };

  const handleSetDimensions = () => {
    if (articleRef.current) {
      const { top, left } = articleRef.current.getBoundingClientRect();
      setDimensions({
        top: top,
        left: left,
        width: articleRef.current.offsetWidth,
        height: articleRef.current.offsetHeight,
      });
    }
  };

  return (
    <>
      <div onMouseEnter={handleSetDimensions} onClick={changeViewToPageDetails}>
        <article ref={articleRef} className="bg-gray-50 border border-gray-100 border-1 shadow sm:rounded-md hover:cursor-pointer hover:bg-gray-100/90 hover:border-gray-200 hover:shadow-md duration-200">
          <header className="px-4 py-5 sm:px-6">
            <div className="flex justify-between items-start">
              <div>
                <p style={{ color: getSecondaryColor(), opacity: 0.7 }} className="mb-2 text-blue-500 font-semibold text-xs">
                  Page {index + 1}
                </p>
                <h2 id="applicant-information-title" className="text-lg font-semibold leading-6 text-gray-900">
                  {item?.pageType}
                </h2>
              </div>
              {team.length > 0 && (
                <AnimatePresence initial={false}>
                  {displayWriterInfo && (
                    <motion.div className="writers-overview flex items-center justify-end gap-x-3" initial={{ opacity: 0, scale: 0.7 }} animate={{ opacity: 1, scale: 1, transition: { type: "spring", stiffness: 150, duration: 0.1 } }} exit={{ opacity: 0, scale: 0.75, transformOrigin: "center center" }}>
                      {/* Avatars */}
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 text-zinc-700">
                        <path d="M4.5 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM14.25 8.625a3.375 3.375 0 116.75 0 3.375 3.375 0 01-6.75 0zM1.5 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM17.25 19.128l-.001.144a2.25 2.25 0 01-.233.96 10.088 10.088 0 005.06-1.01.75.75 0 00.42-.643 4.875 4.875 0 00-6.957-4.611 8.586 8.586 0 011.71 5.157v.003z" />
                      </svg>

                      {/* Avatars */}
                      <div>
                        <ClickMuncher>
                          <HoverModal team={team}>
                            <div className="isolate flex -space-x-1 overflow-hidden">
                              {team.map((member, index) => (
                                <img key={index} className="relative z-30 inline-block h-6 w-6 rounded-full ring-2 ring-white" src={member.image} alt={member.name} />
                              ))}
                            </div>
                          </HoverModal>
                        </ClickMuncher>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              )}
            </div>
          </header>
          <div className="px-4 pb-5 sm:px-6">
            <p className="mt-1 text-sm text-gray-500">{item?.pageType}</p>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <dl className="flex gap-x-6 gap-y-8">
              <div className="grow overflow-hidden">
                <dt className="text-xs font-medium text-gray-400">URL</dt>
                <dd className="mt-2 text-sm text-gray-900 flex items-center gap-x-2 ">
                  <LinkIcon className="h-3 w-3 text-gray-400"></LinkIcon>
                  <span className="flex items-center truncate">{item?.pageUrl}</span>
                </dd>
              </div>
              {/*
              <div className="text-right">
                <dt className="text-xs font-medium text-gray-400">Search&nbsp;Vol</dt>
                <dd className="mt-2 text-sm text-gray-900 font-semibold">2,304</dd>
              </div>
              <div className="text-right">
                <dt className="text-xs font-medium text-gray-400">Keyword&nbsp;Diff</dt>
                <dd className="mt-2 text-sm text-gray-900 font-semibold">32</dd>
              </div>
              */}
            </dl>
          </div>
        </article>
      </div>
    </>
  );
}

const ClickMuncher = ({ children }) => {
  function handleStop(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  return (
    <div onMouseDown={(e) => handleStop(e)} onClick={(e) => handleStop(e)}>
      {children}
    </div>
  );
};
