import React, { createContext, useContext, useState } from "react";

const RoutingContext = createContext();
export const useRoutingContext = () => useContext(RoutingContext);

export default function RoutingContextProvider(props) {
  const [articleDetailsView, setArticleDetailsView] = useState(false); // ["webcopy", "blogger"
  const [pageDetailsView, setPageDetailsView] = useState(false); // ["webcopy", "blogger"
  const [articleId, setArticleId] = useState(null);
  const [dimensions, setDimensions] = useState({ top: 0, left: 0, width: 0, height: 0 });
  const [articleIndex, setArtcileIndex] = useState(null); // Pass the article index from the article list to the article details view

  return (
    <RoutingContext.Provider
      value={{
        articleDetailsView,
        setArticleDetailsView,
        pageDetailsView,
        setPageDetailsView,
        articleId,
        setArticleId,
        articleIndex,
        setArtcileIndex,
        dimensions,
        setDimensions,
      }}
    >
      {props.children}
    </RoutingContext.Provider>
  );
}
