import React, { useState, useRef, useLayoutEffect, useEffect, useMemo } from "react";

import { useSettingsContext } from "../../utils/SettingsContext";
import { Link, useLocation } from "react-router-dom";

import { motion, useAnimation } from "framer-motion";
import { ChevronRightIcon, LinkIcon } from "@heroicons/react/20/solid";
import Avatar from "@mui/material/Avatar";
import StyledTooltip from "../StyledToolTip/StyledToolTip";
import { useReportContext } from "../../utils/ReportContext";
import ReportTabs from "../ReportTabs/ReportTabs";
import PageDetailsContent from "./PageDetailsContent";
import PageDetailsOptimization from "./PageDetailsOptimization";
import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import { useRoutingContext } from "../../utils/RoutingContext";

const TRANSITION_DURATION_SLOW = 0.45; // TODO Move to utils aninations
const TRANSITION_DURATION_FAST = 0.2;

export default function PageDetails(props) {
  const location = useLocation();
  const { reportData, isLoading } = useReportContext();
  const [details, setDetails] = useState([]);
  const [team, setTeam] = useState([]);
  const [currentTab, setCurrentTab] = useState("Optimization");
  const { settingsOpen, setSettingsOpen, getSecondaryColor, agencyName, imageServeUrl, displayWebsite, agencyWebsite, displayWriterInfo, displayNotes } = useSettingsContext();
  const { setArticleId, setPageDetailsView, dimensions, articleIndex } = useRoutingContext();
  const [item, setItem] = useState(null);

  const tabs = [
    { name: "Optimization", current: true },
    { name: "Content", current: false },
  ];

  // Get the starting animations for the header card
  const pageCount = useMemo(() => location.state?.count || "", [location.state]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const animationControls = useAnimation();
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: TRANSITION_DURATION_FAST,
        type: "tween",
        delayChildren: TRANSITION_DURATION_SLOW / 2,
      },
    },
  };

  useEffect(() => {
    if (reportData.orderid) {
      setItem(reportData.items[articleIndex]);
      setTeam(reportData.items[articleIndex].team);
      if (!reportData.includesOptimization) {
        setCurrentTab("Content");
      }
    }
  }, [reportData, articleIndex]);

  /*const item = {
    hidden: { y: "100%" },
    show: { y: "0", transition: { duration: TRANSITION_DURATION_FAST, ease: "easeInOut" } },
  };*/

  useLayoutEffect(() => {
    animationControls.start({ x: 0, y: 0, width: "100%", height: "250px", transition: { type: "tween", ease: "easeOut", duration: TRANSITION_DURATION_SLOW } });
  }, [animationControls, dimensions]);
  const headerRef = useRef(null);

  useEffect(() => {
    setTeam([
      {
        title: "Content Writer",
        value: "Phoenix Baker",
        image: "https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      },
      {
        title: "Quality Control",
        value: "Candice Wu",
        image: "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      },
    ]);
  }, []);

  return (
    <div className="-ml-6 -mr-6 -mt-4 pt-4 pb-6 px-6 bg-gray-50/80 rounded-2xl">
      {/* Top container with Logo and action buttons */}
      <div className="px-4 py-5 sm:px-6">
        <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
          <div className="ml-4 mt-4">
            <div className="flex items-center gap-x-2 font-semibold text-lg">
              {/* Default Branded: HOTH logo */}
              {!settingsOpen ? (
                <div>
                  <img className="h-10" src={(process.env.REACT_APP_VERCEL_URL ? 'https://' + process.env.REACT_APP_VERCEL_URL : '') + "/images/thehoth-logo.svg"} alt="The HOTH logo" />
                </div>
              ) : (
                <div className="flex items-center gap-x-3">
                  {/* If white label (settings open): Client logo & name or placeholder */}
                  {!imageServeUrl ? (
                    <div className="bg-gray-200 h-12 w-12 rounded-full grid place-items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 text-white opacity-80">
                        <path fillRule="evenodd" d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z" clipRule="evenodd" />
                      </svg>
                    </div>
                  ) : (
                    <>
                      <Avatar src={imageServeUrl} aria-label="logo avatar" />
                    </>
                  )}
                  {!agencyName ? (
                    <div>
                      <StyledTooltip arrow title="To edit your logo, please use the side panel.">
                        <span className="py-1">Company Name</span>
                      </StyledTooltip>
                    </div>
                  ) : (
                    <span>{agencyName}</span>
                  )}
                </div>
              )}
            </div>
            <div className="text-sm font-semibold ml-2 mt-1">{displayWebsite ? agencyWebsite : null}</div>
          </div>
          {!settingsOpen && (
            <div className="ml-4 mt-4 flex flex-shrink-0">
              <button type="button" className="relative inline-flex items-center rounded-md px-5 py-2.5 text-sm font-semibold shadow-sm ring-0 ring-inset ring-gray-300 bg-zinc-700/20 duration-200 hover:bg-zinc-700/30">
                <span>Contact Support</span>
              </button>
              <button onClick={() => setSettingsOpen(true)} type="button" className="relative ml-3 bg-red-600 text-white hover:bg-red-600 duration-200 inline-flex items-center hover:shadow-lg opacity-90 rounded-md px-5 py-2.5 text-sm font-semibold shadow-sm ring-0 ring-inset ring-gray-300 duration-200">
                <span>Customize & Share</span>
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Header with page details */}
      <motion.div className="relative rounded-lg" animate={{ x: 0, y: 0, opacity: 1, transition: { type: "tween", duration: TRANSITION_DURATION_SLOW } }}>
        {/* Header card */}
        <div>
          <motion.div ref={headerRef} initial={{ backgroundColor: "white", opacity: 1, x: 0, y: dimensions.top - 105, width: dimensions.width, height: dimensions.height }} animate={animationControls}>
            <motion.section variants={container} initial="hidden" animate="show" className="page-header relative z-40 bg-gray-100 border border-gray-200/0 border-1  rounded-lg h-full">
              {/* Title + Meta */}
              <div className="flex-1 px-4 py-5 sm:p-6 flex flex-col">
                {/* Breadcrumbs */}
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { type: "tween", duration: 0.2, delay: TRANSITION_DURATION_SLOW * 0.9 } }}>
                  <nav className="flex mb-2 mt-4" aria-label="Breadcrumb">
                    <ol className="flex items-center space-x-1">
                      <li>
                        <div
                          className="flex items-center"
                          onClick={() => {
                            setPageDetailsView(false);
                            setArticleId(null);
                          }}
                        >
                          <div className="text-sm font-medium text-gray-500 hover:text-gray-700 cursor-pointer">Overview</div>
                        </div>
                      </li>
                      <li>
                        <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                      </li>
                      <li>
                        <div className="flex items-center">
                          <div style={{ color: getSecondaryColor(), opacity: 1 }} className="text-sm font-semibold text-blue-500">
                            {item?.pageType}
                          </div>
                        </div>
                      </li>
                    </ol>
                  </nav>
                </motion.div>

                {/* Title */}

                <div className="min-h-9">
                  <h1 className="font-bold font-gray-900 text-xl min-h-[32px] lg:text-3xl whitespace-normal line-clamp-2">{item?.title.toString()}</h1>

                  <div className="mt-2 text-sm text-gray-900 flex items-center gap-x-2">
                    <LinkIcon className="min-w-[1rem] h-3 w-3 text-gray-400"></LinkIcon>
                    <a href={item?.pageUrl} className="flex underline-none hover:underline text-gray-500 items-center whitespace-nowrap line-clamp-1">
                      {item?.pageUrl}
                    </a>
                  </div>
                </div>

                {/* Meta data + writers / team */}
                <div className="flex justify-between items-center mt-8">
                  {/* Meta Data (Sub Order ID, Page Type) */}
                  <dl className="flex flex-wrap gap-x-10">
                    <div className="relative overflow-hidden rounded-lg pt-2 pb-1">
                      <dt className="flex items-center">
                        <p style={{ color: getSecondaryColor(), opacity: 1 }} className="text-xs font-semibold leading-6 truncate text-blue-500">
                          Sub Order ID
                        </p>
                        <StyledTooltip placement={"top"} maxWidth={200} arrow title={"This order is part of an order with multiple pages"}>
                          <div className="p-1 rounded-full group cursor-help">
                            <QuestionMarkCircleIcon style={{ color: getSecondaryColor() }} className="h-4 w-4 opacity-40 group-hover:opacity-70 duration-200" />
                          </div>
                        </StyledTooltip>
                      </dt>
                      <dd className="mt-0.5 flex items-baseline gap-x-2">{isLoading ? <div className="skeleton-value animate-pulse h-7 w-16 bg-slate-500 rounded-full opacity-10"></div> : <span className="text-xl font-semibold opacity-7">{item?.id}</span>}</dd>
                    </div>
                    <div className="relative overflow-hidden rounded-lg pt-2 pb-1">
                      <dt>
                        <p style={{ color: getSecondaryColor(), opacity: 1 }} className="text-xs font-semibold leading-6 truncate text-blue-500">
                          Page Type
                        </p>
                      </dt>
                      <dd className="mt-0.5 flex items-baseline gap-x-2">{isLoading ? <div className="skeleton-value animate-pulse h-7 w-16 bg-slate-500 rounded-full opacity-10"></div> : <span className="text-xl font-semibold opacity-7">{item?.pageType}</span>}</dd>
                    </div>
                  </dl>

                  {/* Writers */}
                  {displayWriterInfo && (
                    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { type: "tween", delay: TRANSITION_DURATION_SLOW * 0.8 } }}>
                      <dl className="flex flex-wrap gap-x-12">
                        {team ? (
                          <>
                            {team.map((t) => (
                              <div key={t.title} className="relative overflow-hidden rounded-lg pt-2 flex gap-x-3">
                                <img className="inline-block h-10 w-10 rounded-full" src={t.image} alt="" />
                                <div>
                                  <dt>
                                    <p className="text-xs font-semibold leading-6 truncate opacity-50">{t.title}</p>
                                  </dt>
                                  <dd className="-mt-1 flex items-baseline gap-x-2">
                                    <span className="text-md font-semibold">{t.value}</span>
                                  </dd>
                                </div>
                              </div>
                            ))}
                          </>
                        ) : null}
                      </dl>
                    </motion.div>
                  )}
                </div>
              </div>
            </motion.section>
          </motion.div>
        </div>
      </motion.div>

      {/* Page details tabs and content of the tabs */}
      <motion.div>
        <div className="">
          <div className="min-h-full">
            <motion.main initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { type: "tween", delay: 0.3 } }} className="pb-4 pb-20">
              <>
                {(reportData.includesContent && reportData.includesOptimization) ||
                  (true && (
                    <>
                      <ReportTabs tabs={tabs} currentTab={currentTab} onToggle={(value) => setCurrentTab(value)}></ReportTabs>
                    </>
                  ))}
                {(reportData.includesOptimization || true) && currentTab === "Optimization" && <PageDetailsOptimization />}
                {reportData.includesContent && currentTab === "Content" && <PageDetailsContent item={item} />}
              </>
            </motion.main>
          </div>
        </div>
      </motion.div>
    </div>
  );
}
