import React from "react";

export default function PagesLoadingSkeleton(props) {
  return (
    <>
      <ul className="space-y-6 mb-4">
        {[...Array(3)].map((_, index) => (
          <li key={index} className="skeleton-page opacity-100 bg-gray-50 shadow border-gray-100 border sm:rounded-md duration-300">
            <header className="animate-pulse px-4 py-5 sm:px-6">
              <div className="flex justify-between items-start">
                <div className="space-y-4">
                  <div className="skeleton-page__artcile-count h-2 w-12 bg-slate-200 rounded"></div>
                  <div className="skeleton-page__title h-2 w-56 bg-slate-200 rounded"></div>
                </div>
                <div className="flex items-center justify-end gap-x-3">
                  <div className="skeleton-page__avatars rounded-full bg-slate-200 h-7 w-7"></div>
                </div>
              </div>
            </header>
            <div className="animate-pulse px-4 pb-5 sm:px-6">
              <div className="skeleton-page__description flex-1 space-y-6 pt-2 py-1">
                <div className="space-y-3">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                  </div>
                  <div className="h-2 bg-slate-200 rounded"></div>
                </div>
              </div>
            </div>
            <div className="animate-pulse border-t border-gray-100 px-4 py-5 sm:px-6">
              <dl className="flex gap-x-6 gap-y-8">
                <div className="grow">
                  <dt className="text-xs font-medium text-gray-400">
                    <div className="skeleton-page__artcile-label h-2 w-12 bg-slate-200 rounded"></div>
                  </dt>
                  <dd className="mt-2 text-sm text-gray-900 flex items-center gap-x-2">
                    <span className="flex items-center">
                      <div className="skeleton-page__artcile-value h-2 w-32 bg-slate-200 rounded-full"></div>
                    </span>
                  </dd>
                </div>
                <div className="text-right">
                  <div className="skeleton-page__artcile-label h-2 w-12 bg-slate-200 mb-2 rounded"></div>
                  <div className="skeleton-page__artcile-value ml-auto h-2 w-8 bg-slate-200 rounded"></div>
                </div>
                <div className="text-right">
                  <div className="skeleton-page__artcile-label h-2 w-12 bg-slate-200 mb-2 rounded"></div>
                  <div className="skeleton-page__artcile-value ml-auto h-2 w-8 bg-slate-200 rounded"></div>
                </div>
              </dl>
            </div>
          </li>
        ))}
      </ul>
    </>
  );
}
