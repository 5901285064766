import { Tooltip } from "@mui/material";

export default function StyledTooltip({ title, placement, arrow, children, maxWidth }) {
  return (
    <Tooltip
      title={title}
      {...(arrow && { arrow: true })}
      placement={placement || "bottom"}
      PopperProps={{
        popperOptions: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -10],
              },
            },
          ],
        },
      }}
      componentsProps={{
        tooltip: {
          sx: {
            maxWidth: maxWidth || "none",
            pointerEvents: "none",
            fontFamily: "Proxima Nova",
            padding: "4px 10px",
            fontWeight: "normal",
            fontSize: 13,
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
            bgcolor: "rgba(0,0,0,1)",
            "& .MuiTooltip-arrow": {
              color: "#232323",
            },
          },
        },
      }}
      disableInteractive
    >
      {children}
    </Tooltip>
  );
}
