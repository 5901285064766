import React from "react";

export default function WebsiteInput(props) {
  const { title, state, setState } = props;

  function handleChange(e) {
    setState(e.target.value);
  }

  return (
    <div className="mt-0 mb-3">
      {title ? (
        <label htmlFor={title} className="block mb-1 text-sm font-semibold leading-6 text-gray-900">
          {title}
        </label>
      ) : null}
      <div className="mt-2 flex rounded-md shadow-sm">
        <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">https://</span>
        <input type={title} name={title} id={title} className="block w-full pl-2 min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6" placeholder="www.example.com" value={state} onChange={handleChange} />
      </div>
    </div>
  );
}
